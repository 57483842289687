import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

import './login.css'

const ImageSection = () => {

  const style = {
    center : {
      "textAlign" : "center"
    }
  }
  return(
    <div className={"d-none d-md-flex col-md-7 col-lg-7 bg-image"}>
      <Row className={'h-100'}>
        <Col className={'my-auto'} style={style.center}>
        </Col>
      </Row>
    </div>
  )
}

export default (withTranslation()(ImageSection))