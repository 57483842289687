import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './utils/privateRoute';
import PublicRoute from './utils/publicRoute';

import { Login, SessionExpired, Registration, Unauthorize, NotFound } from './components'
import Dashboard from './pages/dashboard';
import Synchronisation from './pages/synchronisation';
import Configuration from './pages/configuration';

const componentOf = {
	Dashboard: Dashboard,
	Synchronisation: Synchronisation,
  Configuration: Configuration
};

const Router = ({pages}) => {
  return (
    <Routes>
      <Route path='/registration' element={<Registration />} />
      <Route path='/expired' element={<SessionExpired />} />
      <Route path='/unauthorized' element={
        <PublicRoute>
          <Unauthorize />
        </PublicRoute>
        } 
      />
      {pages && pages.map((element, index) => {
        return(
          <Route key={`route-${element.name}-${index}`} path={element.path} element={
            <PrivateRoute key={`private-route-${element.name}-${index}`} path={element.path} component={componentOf[element.name]} />
            } 
          /> 
        )
      })}
      <Route path='/' element={
        <PublicRoute>
          <Login />
        </PublicRoute>
        } 
      />
      <Route
        path="*"
        element={<NotFound />}
      />
    </Routes>
  );
};

export default Router;