import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { registrationSignIn, registrationSubmit } from '../../redux/actions'
import { Button } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

const RegistrationButton = (props) => {
  const dispatch = useDispatch()
  const tenant_admin = useSelector((state) => state.tenant_admin)
  const access_token = useSelector((state) => state.access_token)

  const {t} = props
  const onClickHandler = () => {
    dispatch(tenant_admin ? registrationSubmit({
      idToken:access_token,
      tenantId:tenant_admin.response.tenantId,
      tenantState:'processing'
    },tenant_admin.response.accessToken) : registrationSignIn())
  }

  const buttonText = tenant_admin ? t('button-registration-finish') : t('button-registration');
  return (
    <Button variant="primary" onClick={onClickHandler}>
      {buttonText}
    </Button>
  )
}

export default withTranslation()(RegistrationButton)