import React, { useState, useEffect }  from 'react';

function getAnglePoint(startAngle, endAngle, radius, x, y) {
	var x1, y1, x2, y2;

	x1 = x + radius * Math.cos(Math.PI * startAngle / 180);
	y1 = y + radius * Math.sin(Math.PI * startAngle / 180);
	x2 = x + radius * Math.cos(Math.PI * endAngle / 180);
	y2 = y + radius * Math.sin(Math.PI * endAngle / 180);

	return { x1, y1, x2, y2 };
}

class Pie extends React.Component{

	render() {
		var colors = this.props.colors,
			colorsLength = colors.length,
			labels = this.props.labels,
			hole = this.props.hole,
			radius = this.props.radius,
			diameter = radius * 2,
			self = this,
			sum, startAngle,

		sum = this.props.data.reduce(function (carry, current) { return carry + current }, 0);
		startAngle = 0;


		return (
			<svg className='chart-container' width={ diameter } height={ diameter } viewBox={ '0 0 ' + diameter + ' ' + diameter } xmlns="http://www.w3.org/2000/svg" version="1.1">
				{ this.props.data.map(function (slice, sliceIndex) {
					var angle, nextAngle, percent;

					nextAngle = startAngle;
					angle = (slice / sum) * 360;
					percent = (slice / sum) * 100;
					startAngle += angle;

					if(percent === 100)
						angle = 359

					return <Slice
						key={ sliceIndex }
						value={ slice }
						percent={ self.props.percent }
						percentValue={ percent.toFixed(1) }
						startAngle={ nextAngle }
						angle={ angle }
						radius={ radius }
						hole={ radius - hole }
						trueHole={ hole }
						showLabel= { labels }
						fill={ colors[sliceIndex % colorsLength] }
						stroke={ self.props.stroke }
						strokeWidth={ self.props.strokeWidth }
					/>
				}) }
			</svg>
		);
	}
}

const Slice = (props) => {

  const [data, setData ] = useState({
    path: '',
    x: 0,
    y: 0
  })

  const [_isMounted, set_isMounted ] = useState(true)

  useEffect(() => {
    setData(prevData => ({
      ...prevData,
      path: ''
    }));
    set_isMounted(true)
    animate();

    return () => {
      set_isMounted(false)
    }

  },[props.radius]);

	const animate = () => {
		draw(0);
  }
  
	const draw = (s) => {
		if (!_isMounted) {
			return;
		}

		var p = props, path = [], a, b, c, self = this, step;
		step = p.angle / (37.5 / 2);

		if(s + step > p.angle) {
			s = p.angle;
		}

		a = getAnglePoint(p.startAngle, p.startAngle + s, p.radius, p.radius, p.radius);
		b = getAnglePoint(p.startAngle, p.startAngle + s, p.radius - p.hole, p.radius, p.radius);

		path.push('M' + a.x1 + ',' + a.y1);
		path.push('A'+ p.radius +','+ p.radius +' 0 '+ (s > 180 ? 1 : 0) +',1 '+ a.x2 + ',' + a.y2);
		path.push('L' + b.x2 + ',' + b.y2);
		path.push('A'+ (p.radius- p.hole) +','+ (p.radius- p.hole) +' 0 '+ (s > 180 ? 1 : 0) +',0 '+ b.x1 + ',' + b.y1);

		path.push('Z');

    setData(prevData => ({
      ...prevData,
      path: path.join(' ')
    }));

		if (s < p.angle) {
      //not animatited
      //self.draw(s + step)
      //animatited
			setTimeout(function () { draw(s + step) } , 10);
		} else if (p.showLabel) {
			c = getAnglePoint(p.startAngle, p.startAngle + (p.angle / 2), (p.radius / 2 + p.trueHole / 2), p.radius, p.radius);
      
      setData(prevData => ({
        ...prevData,
        x: c.x2,
				y: c.y2
      }));

		}
  }
  
  return (
    <g overflow="hidden" className="pie-chart">
      <path
        d={ data.path }
        fill={ props.fill }
        stroke={ props.stroke }
        strokeWidth={ props.strokeWidth ? props.strokeWidth : 3 }
          />
      { props.showLabel && props.percentValue > 5 ?
        <text className="value-label" x={ data.x } y={ data.y } fill="#fff" textAnchor="middle">
          { props.percent ? props.percentValue + '%' : props.value }
        </text>
      : null }
    </g>
  );
}

export default Pie