import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getTasks, newTask, editTask, removeTask, runTasks } from '../redux/actions'
import { useModalWithData } from '../hooks/useModal'
import { Container, Row, Col, ListGroup, Button, Pagination } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { SynchronisationModal, SynchronisationItem, SynchronisationHeader } from '../components'
import { splitDynamicFilterGroup, splitDynamicFilterQuery } from '../utils/queryHelper'
import InputField from '../components/inputField'

const Synchronisation = ({actions}) => {
  const {t} = useTranslation()
  const identity = useSelector((state) => state.identity)
  const tenant = useSelector((state) => state.tenant)
  const tasks = useSelector((state) => state.tasks)
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen, selected, setSelected, setModalState, modalEdit, setModalEdit] = useModalWithData(false);
  const [size, setSize] = useState([0, 0]);
  const [limit, setLimit] = useState(25);
  const [skip, setSkip] = useState(0);
  const searchRef = useRef()
  const startTimerRef = useRef()
  const [searchString, setSearchString] = useState();
  const [typingTimeout, setTypingTimeout] = useState();

  const nextPage = () => {
    setSkip(skip + limit)
  }

  const previousPage = () => {
      setSkip(skip - limit)
  }

  useEffect(() => {
    searchRef.current = searchString;
  },[searchString])

  useEffect(() => {
    const startTimer = () => {
      setTimeout(()=> {
        dispatch(getTasks(limit, skip, searchRef.current))
        if(startTimerRef.current === 'mount')
          startTimer()
      }, 10000);
    }
    startTimerRef.current = 'mount';
    startTimer()
    return () => {
      startTimerRef.current = null
    };
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    dispatch(getTasks(limit, skip, searchRef.current))
    // eslint-disable-next-line
  }, [limit, skip])
  
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  if(!identity || !tenant || tenant === 'Unauthorized')
    return null

  const createTaskTemplate = () => {
    setSelected({
      description: null,
      source: null,
      target: null,
      owner: identity.account.userName,
      active: true,
      extendedFilter: false,
      blacklistFilter: [],
      whitelistFilter: []
    })
    setModalOpen(true)
    setModalEdit(true)
  }

  const handleOnClickTask = (params) => {
    var objects = tasks.filter((object) => { return object._id === params })
    setSelected(objects[0])
    setModalOpen(true)
  }

  const handleOnChangeInput = (e) => {
    const { id, value } = e.target
    setSelected(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnChangeSelect = (e) => {
    const { id, displayName, controlId } = e
    setSelected(prevData => ({
      ...prevData,
      [controlId]: {id, displayName }
    }))
  }

  const handleOnAddSelectUser = (e) => {
    if(e) {
      const { id, displayName, controlId } = e
      let array = selected[controlId]
      if(!array)
        array = []
      array.push({id, displayName})
      setSelected(prevData => ({
        ...prevData,
        [controlId]: array
      }))
    }
  }

  const handleOnRemoveSelectUser = (e, filterList) => {
    if(e) {
      let array = selected[filterList].filter(element => element.id !== e)
      setSelected(prevData => ({
        ...prevData,
        [filterList]: array
      }))
    }
  }

  const handleOnAddFilter = (filterGroupIndex) => {
    var filterGroupArray = splitDynamicFilterGroup(selected.source.id)
    var filter = filterGroupArray[filterGroupIndex]
    if(filter)
      filter = `${filter} and country eq'value'`
    else
      filter = `country eq'value'`
    filterGroupArray[filterGroupIndex] = filter
    setSelected(prevData => ({
      ...prevData,
      source: {id: filterGroupArray.join(' '), displayName: "Dynamic Filter"}
    }))
  }

  const handleOnDeleteFilter = (i, filterGroupIndex) => {
    var filterGroupArray = splitDynamicFilterGroup(selected.source.id)
    var filterArray = splitDynamicFilterQuery(filterGroupArray[filterGroupIndex])
    const result = [...filterArray.slice(0, i), ...filterArray.slice(i + 1)];
    filterGroupArray[filterGroupIndex] = result.join(' ')
    setSelected(prevData => ({
      ...prevData,
      source: {id: filterGroupArray.join(' '), displayName: "Dynamic Filter"}
    }))
  }

  const handleOnChangeFilter = (e, index, filterGroupIndex) => {
    var filterGroupArray = splitDynamicFilterGroup(selected.source.id)
    var filterArray = splitDynamicFilterQuery(filterGroupArray[filterGroupIndex])
    filterArray[index] = e
    filterGroupArray[filterGroupIndex] = filterArray.join(' ')
    setSelected(prevData => ({
      ...prevData,
      source: {id: filterGroupArray.join(' '), displayName: "Dynamic Filter"}
    }))
  }

  const handleOnAddFilterGroup = (event) => {
    var filterGroups = selected.source ? selected.source.id : null
    if(!filterGroups)
      filterGroups = `country eq'value'`
    else
      filterGroups = `${filterGroups} ${event} country eq'value'`
    setSelected(prevData => ({
      ...prevData,
      source: {id: filterGroups, displayName: "Dynamic Filter"}
    }))
  }

  const handleOnSave = () => {
    if(selected._id)
      dispatch(editTask(selected))
    else
      if(selected.source.id !== selected.target.id && selected.source && selected.target)
        dispatch(newTask(selected))
    setModalState(false)
  }

  const handleOnRemove = () => {
    if(selected._id)
      dispatch(removeTask(selected._id))
    setModalState(false)
  }

  const handleOnSearch = (e) => {
    const { value } = e.target
    if (typingTimeout) {
      clearTimeout(typingTimeout);
      setTypingTimeout(null);
    }
    setTypingTimeout(
      setTimeout(async () => {
        dispatch(getTasks(limit, skip, value))
        setSearchString(value)
      }, 1200)
    )
  }

  const handleOnRunTask = (id) => {
    dispatch(runTasks(id))
  }

  const handleOnSetState = (id) => {
    var objects = tasks.filter((object) => { return object._id === id })
    objects[0].active = !objects[0].active
    dispatch(editTask(objects[0]))
  }

  return(
    <Container fluid>
      <SynchronisationModal
        actions={actions}
        edit={modalEdit}
        setEdit={setModalEdit}
        modalOpen={modalOpen}
        selected={selected}
        onModalClose={setModalState}
        onInputChange={handleOnChangeInput}
        onSelectChange={handleOnChangeSelect}
        onSelectUserAdd={handleOnAddSelectUser}
        onSelectUserRemove={handleOnRemoveSelectUser}
        onAddFilterGroup={handleOnAddFilterGroup}
        onAddFilter={handleOnAddFilter}
        onChangeFilter={handleOnChangeFilter}
        onDeleteFilter={handleOnDeleteFilter}
        onModalSave={handleOnSave}
        onModalRemove={handleOnRemove}
      />
      <Row className="mt-25">
        <Col>
          <h1 className="fw-bold">{t('synchronisation-headline')}</h1>
        </Col>
      </Row>
      <Row className="mt-25">
        <Col style={{margin: "auto"}}>
          {actions.includes('create_tasks') &&
            <Button 
              variant="outline-primary"
              onClick={createTaskTemplate}
            >
              <FontAwesomeIcon icon={faPlus} style={{marginRight: 7}}/>{t('button-create-dynamic-group')}
            </Button>
          }
        </Col>
        <Col>
          <InputField
            type="text"
            edit
            label={t('input-label-searchbar')}
            placeholder={t('placeholder-label-searchbar')}
            handleOnChange={handleOnSearch}
          />
        </Col>
      </Row>
      {tasks ? 
      <div>
        <Row className="mt-25">
          <ListGroup variant="flush">
            {size > 844 && <SynchronisationHeader />}
            {tasks.map((task) => {
              return(
                <SynchronisationItem
                  actions={actions}
                  source={task.source}
                  target={task.target}
                  active={task.active}
                  synchronisation={task.synchronisation}
                  taskId={task._id}
                  key={`task-card-${task._id}`}
                  onOpen={handleOnClickTask}
                  onClickRun={handleOnRunTask}
                  onClickState={handleOnSetState}
                />
              )
            })}
          </ListGroup>
        </Row>
        {tasks.length >= limit && <Row className="mt-25">
          <Pagination>
            <Pagination.Prev onClick={previousPage} />
            <Pagination.Next onClick={nextPage} />
          </Pagination>
        </Row>}
      </div>
      :
      <div style={{minHeight: "90vh"}} className={"d-grid align-items-center"}>
        <Row style={{textAlign: "center"}}>
          <Col xs={12}>
            <h2 className="fw-bold">{t('synchronisation-headline')}</h2>
          </Col>
          <Col xs={12}>
            <h4>{t('synchronisation-subheadline')}</h4>
          </Col>
          <Col xs={12}>
            {actions.includes('create_tasks') &&
              <Button 
                variant="outline-primary"
                onClick={createTaskTemplate}
              >
                <FontAwesomeIcon icon={faPlus} style={{marginRight: 7}}/>{t('button-create')}
              </Button>
            }
          </Col>
        </Row>
      </div>}
    </Container>
  )
}

export default Synchronisation