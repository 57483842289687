import React from 'react'
import LoadingImage from './my-IAM-animated-trans.webp'

const Loading = ({show}) => {

  const style = {
    loaderCover: {
      position:'fixed',
      width:'100%',
      left:0,
      right:0,
      top:0,
      bottom:0,
      backgroundColor: 'rgba(255,255,255,0.7)',
      zIndex:9999
    },
    laoderSpinner: {
      zIndex: 10000,
      position: 'fixed',
      top: '50%',
      right: '50%',
      transform: 'translate(50%, -50%)',
      height: 200
    }
  }

  if(show === false || show === null)
    return null

  return(
    <div style={{...style.loaderCover}}>
      <img style={{...style.laoderSpinner}} src={LoadingImage} alt='my-IAM GroupSync'/>
    </div>
  )
}

export default (Loading);