import React from 'react';
import Router from '../Router';
import { useSelector } from 'react-redux';

import { Loader, Header, Sidebar, Notification } from '../components'

const MainApp = () => {
  const identity = useSelector((state) => state.identity)
  const roleAssignments = useSelector((state) => state.roleAssignments)
  const allowedActions = useSelector((state) => state.allowedActions)
  const tenant = useSelector((state) => state.tenant)
  const sidebar = useSelector((state) => state.sidebar)
  const avatar = useSelector((state) => state.avatar)
  const loading = useSelector((state) => state.loading)
  const notification = useSelector((state) => state.notification)

  return(
    <div>
      <Loader
        show={loading}
      />
      <Notification
        notification={notification}
      />
      <div id="main-panel">
        <Header
          identity={identity}
          roleAssignments={roleAssignments}
          tenant={tenant}
          avatar={avatar}
        />
        <Sidebar
          identity={identity}
          tenant={tenant}
          show={sidebar}
          pages={allowedActions}
        />
        <Router
          pages={allowedActions}
        />
      </div>
    </div>
  )
}

export default MainApp