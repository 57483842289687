import React from 'react'
import { ListGroup } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

const SidebarItem = (props) => {
  const {t} = props

  const style = {
    pointer: {
      cursor: "pointer"
    }
  }
  return(
    <ListGroup.Item
      style={style.pointer}
      className={'fs-5'}
      onClick={()=>props.handleOnClick(props.route)}
    >
      {t(`menu-item-${props.name}`)}
    </ListGroup.Item>
  )
}

export default withTranslation()(SidebarItem)