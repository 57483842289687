import axios from 'axios';
import { Buffer } from 'buffer';
import { removeSession, setTenant, setAccessToken, setRefreshToken } from './common'

class TenantService {
  constructor(configuration) {
    this.serive_api = configuration.serviceUrl
  }

  get serviceName() { return 'my-IAM DynmaicSync'; }

  async signIn(token, tenantId) {
    try {
      var data = JSON.stringify({
        "idToken": token
      });
      var auth = new Buffer.from(window._env_.REACT_APP_SERVICE_API_USER + ":" + window._env_.REACT_APP_SERVICE_API_PASSWORD).toString("base64")
      var config = {
        method: 'post',
        url: `${window._env_.REACT_APP_SERVICE_WEB}/login/oauth2/authorize/${tenantId}/token`,
        headers: { 
          Authorization: 'Basic ' + auth,
          'Content-Type': 'application/json'
        },
        data : data
      };
      const response = await axios(config).catch((err)=>{
        if(err.response){
          if(err.response.status === 401)
            return {data: {success: true, tenant: err.response.data.tenant, auth_token: null} }
        }
      })
      var result = response.data
      if(result.success === true) {
        if(result.tenant.tenantState !== 'unauthorized'){
          setAccessToken(result.auth_token)
          setRefreshToken(result.refresh_token)
          setTenant(result.tenant.tenantId)
        }
        return result
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  async signOut() {
    removeSession()
    return true
  }

}

var iam = {
  "serviceUrl" : window._env_.REACT_APP_MSAL_CLIENTID
}

const tenantService = new TenantService(iam);

export default tenantService;