import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleSidebar } from '../../redux/actions';
import { getAccessToken } from '../../utils/common';
import { Offcanvas, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SidebarItem from './sidebarItem';
import { SignInButton } from '../';

const Sidebar = ({identity, tenant, show, pages}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const style = {
    bottom: {
      bottom: "15px",
      position: "absolute"
    },
    offcanvas: {
      top: 60
    }
  }

  const handleOnSelectRoute = (route) => {
    dispatch(toggleSidebar())
    navigate(`${route}`)
  }

  if(!identity || !tenant || !getAccessToken())
    return null

  return(
    <Offcanvas style={{...style.offcanvas}} show={show} onHide={()=>dispatch(toggleSidebar())}>
      <Offcanvas.Header>
        <Offcanvas.Title className={'display-6 fw-bold'}>{t('sidebar-headline')}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
      <ListGroup variant="flush">
          {pages && pages.map((slide, index) => {
            return(
              <SidebarItem
                key={`menu-item-${slide.name}`}
                name={slide.name}
                route={slide.path}
                handleOnClick={handleOnSelectRoute}
              />
            )
          })}
        </ListGroup>
      </Offcanvas.Body>
      <Offcanvas.Body>
        <div style={style.bottom}>
          <SignInButton/>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default Sidebar