import {useState} from "react";

export const useModal = (initialMode = false) => {
    const [modalOpen, setView] = useState(initialMode);
    const [modalEdit, setModalEdit] = useState(false);
    const setModalOpen = (state) => {
      setView(state)
      setModalEdit(false)
    }
    const toggle = () => setModalOpen(!modalOpen);
    return [modalOpen, setModalOpen, toggle, modalEdit, setModalEdit];
}

export const useModalWithData = (initialMode = false, initialSelected = null) => {
    const [modalOpen, setView] = useModal(initialMode);
    const [modalEdit, setModalEdit] = useState(false);
    const [selected, setSelected] = useState(initialSelected);
    const setModalOpen = (state) => {
      setView(state)
      setModalEdit(false)
    }
    const setModalState = state => {
        setModalOpen(state);
        setModalEdit(false);
        if (state === false) {
            setSelected(null)
        }
    }
    return [modalOpen, setModalOpen, selected, setSelected, setModalState, modalEdit, setModalEdit];
}