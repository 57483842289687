import React from 'react';
import withClearCache from "./ClearCache";

import MainApp from './layouts';

import './App.scss';

const ClearCacheComponent = withClearCache(MainApp)

function App(props) {
  return <ClearCacheComponent />
}

export default (App)