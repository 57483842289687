import React from 'react'
import { Row, Col, ListGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const CustomAttributeListHeader = () => {
  const {t} = useTranslation()

  const style = {
    theader:{
      fontWeight: "bold"
    }
  }
  return(
    <ListGroup.Item>
      <Row>
        <Col lg={7} md={7}>
          <span style={style.theader}>{t('customAttribute-name')}</span>
        </Col>
        <Col xs={false} lg={3} md={3}>
          <span style={style.theader}>{t('customAttribute-label')}</span>
        </Col>
        <Col lg={2} md={2}>
          <span style={style.theader}>{t('customAttribute-action')}</span>
        </Col>
      </Row>
    </ListGroup.Item>
  )
}

export default CustomAttributeListHeader