export const splitDynamicFilterGroup = (filterGroup) => {
  filterGroup = filterGroup.replaceAll(' OR',' <OR>OR')
  filterGroup = filterGroup.replaceAll(' AND', ' <AND>AND')
  var array = filterGroup.split(/(?: <OR>| <AND>)+/).map(function(item) {
    return item.trim();
  });
  return array
}

export const splitDynamicFilterQuery = (filterQuery) => {
  filterQuery = filterQuery.replaceAll(' or',' <or>or')
  filterQuery = filterQuery.replaceAll(' and', ' <and>and')
  var array = filterQuery.split(/(?: <or>| <and>)+/).map(function(item) {
    return item.trim();
  });
  return array
}