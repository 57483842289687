import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Container, Button, Row, Col, ListGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit, faCheck, faBan } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { createCustomSchemaExtension, createOrUpdateNotificationSettings, fetchNotificationSettings, fetchSchemaExtensions, removeCustomSchemaExtension } from '../utils/requestHelper'
import { CustomAttributeListHeader, CustomAttributeListItem, IconButton, InputField } from '../components'
import AddCustomAttributeModal from '../components/modals/addCustomAttributeModal'
import { useModalWithData } from '../hooks/useModal'
import { constants } from 'buffer'

const Configuration = ({actions}) => {
  const {t} = useTranslation()
  const [ modalOpen, setModalOpen, selected, setSelected, setModalState ] = useModalWithData(false)
  const identity = useSelector((state) => state.identity)
  const tenant = useSelector((state) => state.tenant)
  const [ schemaExtensions, setschemaExtensions ] = useState([])
  const [ defaultNotificationMail, setDefaultNotificationMail ] = useState()
  const [ editNotificationEmail, setEditNotificationEmail ] = useState(false)

  useEffect(() => {
    async function loadSchemaExtensionsAsync () {
      var res = await fetchSchemaExtensions(null,{schemaExtension:'customSchemaExtension'})
      if (res.success === true) {
        setschemaExtensions(res.data)
      }
    }
    async function loadNotificationSettingsAsync () {
      var res = await fetchNotificationSettings()
      if (res.success === true) {
        if (res.result)
          setDefaultNotificationMail(res.result.defaultMail)
      }
    }
    loadSchemaExtensionsAsync()
    loadNotificationSettingsAsync()
    // eslint-disable-next-line
  }, [])

  if(!identity || !tenant || tenant === 'Unauthorized')
    return null

  const handleOnClickShowAddCustomAttribute = async() => {
    setModalOpen(true)
  }

  const handleOnClickRemoveCustomAttribute = async(e) => {
    const result = await removeCustomSchemaExtension(e)
    if(result.status === 204) {
      setschemaExtensions(schemaExtensions.filter(x => x.name !== e))
    }
  }

  const handleOnClickAddCustomAttribute = async(e) => {
    const result = await createCustomSchemaExtension(e)
    setSelected(result)
    if(result.success === true) {
      setSelected({...selected, message: `add '${e.label}' sucessfull!`})
      var res = await fetchSchemaExtensions(null,{schemaExtension:'customSchemaExtension'})
      if (res.success === true) {
        setschemaExtensions(res.data)
      }
      setModalState(false)
    }
  }

  const handleOnModalClose = () => {
    setModalState(false)
  }

  const handleOnClickEditNotificationEmail = async(e) => {
    setEditNotificationEmail(true)
  }

  const handleOnClickSaveNotificationEmail = async(e) => {
    const result = await createOrUpdateNotificationSettings({defaultMail:defaultNotificationMail})
    if (result.success)
      setEditNotificationEmail(false)
  }

  const handleOnClickCancelNotificationEmail = async() => {
    const res = await fetchNotificationSettings()
    if (res.success === true) {
      if (res.result)
        setDefaultNotificationMail(res.result.defaultMail)
    }
    setEditNotificationEmail(false)
  }

  const handleOnChangeNotificationEmail = (e) => {
    const { id, value } = e.target
    setDefaultNotificationMail(value)
  }

  return(
    <Container fluid>
      {actions.includes('extension_modify') &&
        <Row>
          <Col>
            <div className="p-5 mb-4 bg-light rounded-3">
              <div className="container-fluid py-5">
                <h1 className="display-6 fw-bold">{t(`customAttributes-headline`)}</h1>
                <p className="col-md-8 fs-5">{t(`customAttributes-content`)}</p>
                <Button variant="outline-primary" onClick={handleOnClickShowAddCustomAttribute}><FontAwesomeIcon icon={faPlus} style={{marginRight: 7}}/>{t('button-addCustomAttribute')}</Button>
              </div>
              <div className="container-fluid py-5">
                <ListGroup variant="flush" style={{'--bs-list-group-bg': 'transparent'}}>
                  {schemaExtensions.length > 0 &&
                    <>
                      <CustomAttributeListHeader />
                      {schemaExtensions.map(element => {
                        return (<CustomAttributeListItem key={`custom-attribute-list-item-${element.name}`} element={element} actions={actions} onRemoveItem={handleOnClickRemoveCustomAttribute} />)
                      })}
                    </>
                  }
                </ListGroup>
              </div>
            </div>
          </Col>
        </Row>
      }
      {actions.includes('extension_modify') &&
        <Row>
          <Col>
            <div className="p-5 mb-4 bg-light rounded-3">
              <div className="container-fluid py-5">
                <h1 className="display-6 fw-bold">{t(`notifications-headline`)}</h1>
                <p className="col-md-8 fs-5">{t(`notifications-content`)}</p>
              </div>
              <div className="container-fluid py-5">
                <Col>
                  <div style={{display: "flex"}}>
                    <InputField
                      type="text"
                      edit={editNotificationEmail}
                      value={defaultNotificationMail}
                      label={t('input-label-notificationEmail')}
                      placeholder={t('placeholder-label-notificationEmail')}
                      handleOnChange={handleOnChangeNotificationEmail}
                    />
                    { editNotificationEmail ? <span style={{marginTop: 9}}><IconButton style={{marginLeft: 5}} icon={faCheck} color={'#00b252'} tooltip={t('tooltip-save')} id={`IconButton-notificationEmail-save`} key={`IconButton-notificationEmail-save`} placement={'top'} onClick={handleOnClickSaveNotificationEmail}/><IconButton style={{marginLeft: 5}} icon={faBan} color={'#646464'} tooltip={t('tooltip-cancel')} id={`IconButton-notificationEmail-cancel`} key={`IconButton-notificationEmail-cancel`} placement={'top'} onClick={handleOnClickCancelNotificationEmail}/></span> : <IconButton style={{marginTop: 9, marginLeft: 5}} icon={faEdit} color={'#646464'} tooltip={t('tooltip-edit')} id={`IconButton-notificationEmail-edit`} key={`IconButton-notificationEmail-edit`} placement={'top'} onClick={handleOnClickEditNotificationEmail}/> }
                  </div>
                </Col>
              </div>
            </div>
          </Col>
        </Row>
      }
      <AddCustomAttributeModal show={modalOpen} onModalClose={handleOnModalClose} onModalSave={handleOnClickAddCustomAttribute} result={selected} />
    </Container>
  )
}

export default Configuration