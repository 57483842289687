import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Container, Button, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { fetchTasksInsights } from '../utils/requestHelper'
import { Pie, LoaderCover } from '../components'
import { useWindowDimensions } from '../hooks/useWindowDimensions'

const Dashboard = ({actions}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const identity = useSelector((state) => state.identity)
  const tenant = useSelector((state) => state.tenant)
  const ref = useRef(null);
  const startTimerRef = useRef()
  const [ pieChartWidth, setPieChartWidth ] = useState(0)
  const [ tasksInsights, setTasksInsights ] = useState([])
  const [ tasksInsightsLoading, setTasksInsightsLoading ] = useState(true)

  const handleOnClickJobs = () => {
    navigate('/synchronisation')
  }

  useEffect(() => {
    async function loadDataAsync () {
      var res = await fetchTasksInsights()
      if (res.success === true) {
        setTasksInsights(res.result)
        setTasksInsightsLoading(false)
      }
    }
    function startTimer() {
      setTimeout(()=> {
        loadDataAsync()
        if(startTimerRef.current === 'mount')
          startTimer()
      }, 10000);
    }
    startTimerRef.current = 'mount';
    loadDataAsync()
    startTimer()
    return () => {
      startTimerRef.current = null
    };
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    if(ref.current)
      setPieChartWidth(ref.current.offsetWidth)
    // eslint-disable-next-line
  }, [ref.current, width, tasksInsights]);

  const convertToTasksInsightsArray = (tasksInsights) => {
    if(!tasksInsights)
      return []
    let array = []
    tasksInsights.map((element, index) => {
      for (const key in element) {
        if (Object.hasOwnProperty.call(element, key)) {
          array.push(element[key])
        }
      }
    })
    return array
  }

  const getStatisticsValue = (array) => {
    let val = ((array[0]* 100) / array.reduce((partialSum, a) => partialSum + a, 0)).toFixed(2)
    let color = '#212529', text = 'statistics-value-good'
    if (val > 97) { color = '#198754'; text = 'statistics-value-good' } else
    if (val > 90) { color = '#bfe041'; text = 'statistics-value-ok' } else
    if (val < 90) { color = '#dc3545'; text = 'statistics-value-warning'  }
    return (<span style={{color: color}}>{t(text)}</span>)
  }

  if(!identity || !tenant || tenant === 'Unauthorized')
    return null

  return(
    <Container fluid>
      <Row>
        <Col>
          <div className="p-5 mb-4 bg-light rounded-3">
            <div className="container-fluid py-5">
              <h1 className="display-6 fw-bold">{t(`dashboard-headline`)}</h1>
              <p className="col-md-8 fs-5">{t(`dashboard-content`)}</p>
              <Button size="lg"  variant="outline-primary" onClick={handleOnClickJobs}>{t('button-jobs')}</Button>
            </div>
          </div>
        </Col>
      </Row>
      {actions.includes('tasks_statistics') &&
        <Row>
          <Col md={6} sm={12}>
            <div className="p-5 mb-4 bg-light rounded-3" >
              <div ref={ref} className="container-fluid py-5">
                <h1 className="display-7 fw-bold">{t(`statistics-headline`)}</h1>
                <LoaderCover
                  loading={tasksInsightsLoading}
                />
                {!tasksInsightsLoading && <p className="col-md-8 fs-4">{t(`statistics-content`)}{' '}{getStatisticsValue(convertToTasksInsightsArray(tasksInsights))}</p>}
                {tasksInsights.map((element, index) => {
                  for (const key in element) {
                    if (Object.hasOwnProperty.call(element, key)) {
                      return (<p key={`p-tag-${key}`} className="col-md-8 fs-4">{t(`${key.replace(":","-")}`)}: {element[key]}</p>)
                    }
                  }
                })}
              </div>
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className="p-5 mb-4 bg-light rounded-3" >
              <div ref={ref} className="container-fluid py-5" style={{textAlign: 'center'}}>
                <LoaderCover
                  loading={tasksInsightsLoading}
                />
                {!tasksInsightsLoading && <Pie
                  data={ convertToTasksInsightsArray(tasksInsights) }
                  radius={ (pieChartWidth / 3) }
                  hole={ (pieChartWidth / 8 )}
                  colors={ ['#198754', '#fd7e14', '#dc3545'] }
                  labels={ true }
                  percent={ true }
                  strokeWidth={ 3 }
                  stroke={ '#fff' }
                />}
              </div>
            </div>
          </Col>
        </Row>
      }
    </Container>
  )
}

export default Dashboard