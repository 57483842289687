import React, { useState, useEffect } from 'react'
import { Modal, Button, Container, Dropdown, Row, Col, Tabs, Tab, ListGroup } from 'react-bootstrap'
import { InputSelect } from '../'
import { InputField } from '../'
import { InputCheck } from '../'
import { InputFilter } from '../'
import { getAzureGroups, fetchPreview, fetchSchemaExtensions } from '../../utils/requestHelper'
import { splitDynamicFilterQuery, splitDynamicFilterGroup } from '../../utils/queryHelper'
import { customArrayFilter } from '../../utils/filterHelper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import IconButton from '../iconButton'
import { useModal } from '../../hooks/useModal'
import SelectUserModal from './selectUserModal'

const SynchronisationModal = (props) => {
  const {t} = useTranslation()
  const [ tab, setTab ] = useState('settings');
  const [ source, setSource ] = useState(null)
  const [ target, setTarget ] = useState(null)
  const [ previewUsers, setPreviewUsers ] = useState()
  const [ schemaExtensions, setschemaExtensions ] = useState([])

  const [modalOpenExclude, setModalOpenExclude, modalEditExclude, setModalEditExclude] = useModal()
  const [modalOpenInclude, setModalOpenInclude, modalEditInclude, setModalEditInclude] = useModal()

  useEffect(() => {
    if(props.selected){
      const fetchData = async() => {
        var result = await fetchSchemaExtensions()
        if(result.success === true)
          setschemaExtensions(result.data)
      }
      setSource(props.selected.source)
      setTarget(props.selected.target)
      fetchData()
    }
  }, [props.selected])

  useEffect(() => {
    if(props.modalOpen===false)
      setTab('settings')
  }, [props.modalOpen])

  useEffect(() => {
    const fetchData = async(data) => {
      var result = await fetchPreview(data)
      if(result.success === true){
        var filteredArr = [].concat(result.result)
        if(props.selected.whitelistFilter)
          props.selected.whitelistFilter.forEach(element => {
            filteredArr.push({id: element.id, displayName: element.displayName, userType: 'Included'})
          });
        filteredArr = filteredArr.reduce((acc, current) => {
          const x = acc.find(item => item.id === current.id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        setPreviewUsers({nextLink:result.nextLink, result: filteredArr, success: result.success})
      }
    }
    if(tab==='preview' && source){
      fetchData(source)
    }
    if(tab !=='preview')
      setPreviewUsers(null)
  }, [tab])

  if(props.modalOpen===false || !props.modalOpen)
    return null

  const handleOnClickNextLink = async () => {
    var result = await fetchPreview(source,previewUsers.nextLink)
    if(result.success === true){
      var array = previewUsers.result.concat(result.result)
      if(props.selected.whitelistFilter)
        props.selected.whitelistFilter.forEach(element => {
          array.push({id: element.id, displayName: element.displayName, userType: 'Included'})
        });
      const filteredArr = array.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      setPreviewUsers({nextLink:result.nextLink, result: filteredArr, success: result.success})
    }
  }

  const handleOnClickAddUser = (e) => {
    switch (e) {
      case 'include':
        setModalOpenInclude(true)
        break;
      case 'exclude':
        setModalOpenExclude(true)
        break;
    }
  }

  const handleOnSaveAddUser = (e) => {
    const { controlId } = e
    props.onSelectUserAdd(e)
    switch (controlId) {
      case 'whitelistFilter':
        setModalOpenInclude(false)
        break;
      case 'blacklistFilter':
        setModalOpenExclude(false)
        break;
    }
  }

  const getPreviewUsersStyle = (id) => {
    var isIncluded = props.selected.whitelistFilter && customArrayFilter(props.selected.whitelistFilter, 'id', id)
    if(isIncluded)
      return {color: '#75b798'}
    var isExcluded = props.selected.blacklistFilter && customArrayFilter(props.selected.blacklistFilter, 'id', id)
    if(isExcluded)
      return {color: '#ea868f', textDecoration: 'line-through'}
  }

  const renderExtendedFilterGroup = (filterGroups) => {
    var filterGroupsArray = splitDynamicFilterGroup(filterGroups)
    return(
      <div>
        {filterGroupsArray.map((element, index) => {
          return renderExtendedFilter(element, index)
        })}
      </div>
    )
  }

  const renderExtendedFilter = (filter, filterGroupIndex) => {
    var array = splitDynamicFilterQuery(filter)
    return (
      <div style={{backgroundColor: '#eff5ff', padding: 10, marginBottom: 10}} key={`dynamic-filtergroup-${filterGroupIndex}`}>
        {array.map((element, index) => {
          return(
            <InputFilter
              key={`extendedFilter-${index}`}
              index={index}
              value={element}
              actions={props.actions}
              filterGroupIndex={filterGroupIndex}
              schemaExtensions={schemaExtensions}
              onSave={props.onChangeFilter}
              onDelete={props.onDeleteFilter}
            />
          )
        })}
        { props.edit &&
          <Button 
            variant="link"
            onClick={()=>props.onAddFilter(filterGroupIndex)}
          >
            {t('button-addFilter')}
          </Button>
        }
      </div>
    )
  }

  return(
      <Modal
        show={props.modalOpen}
        onHide={()=>props.onModalClose(false)}
        backdrop="static"
        keyboard={false}
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title>{target ? target.displayName : t('synchronisation-modal-headline')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{fontSize: "1.0rem"}}>
          <Container>
            <Tabs
              id="controlled-tab-example"
              activeKey={tab}
              onSelect={(k) => setTab(k)}
              className="mb-3"
            >
              <Tab eventKey="settings" title={t('synchronisation-modal-basic')}>
                <Row className='mt-25'>
                  <Col md={12} lg={12} xs={12} style={{textAlign: "right"}}>
                    {props.actions.includes('modify_tasks') && 
                      <IconButton icon={faEdit} color={'#646464'} tooltip={t('tooltip-edit')} id={'modal-edit'} key={'modal-edit'} placement={'top'} onClick={()=>props.setEdit(!props.edit)}/>
                    }
                  </Col>
                  <Col md={12} lg={12}>
                    <InputSelect
                      key={`input-filter-target`}
                      label={t('input-label-target')}
                      placeholder={t('placeholder-label-target')}
                      controlId='target'
                      onPremisesSyncDisabled
                      options={getAzureGroups}
                      edit={props.edit}
                      handleOnChange={props.onSelectChange}
                      value={target}
                      filter={{onPremisesSyncEnabled:'null'}}
                    />
                  </Col>
                  <Col md={12} lg={12}>
                    <InputField 
                      label={t('input-label-description')}
                      controlId='description'
                      type='textarea'
                      value={props.selected.description}
                      edit={props.edit}
                      handleOnChange={props.onInputChange}
                    />
                  </Col>
                  <Col md={12} lg={12}>
                    <InputCheck 
                      type="switch"
                      controlId='extendedFilter'
                      label={t('input-label-extendedFilter')}
                      value={t(`selected-label-${props.selected.extendedFilter}`)}
                      checked={props.selected.extendedFilter}
                      edit={props.edit}
                      handleOnChange={props.onInputChange}
                    />
                  </Col>
                  {!props.selected.extendedFilter ? (
                    <Col md={12} lg={12}>
                      <InputSelect
                        key={`input-select-source`}
                        label={t('input-label-source')}
                        placeholder={t('placeholder-label-source')}
                        controlId='source'
                        options={getAzureGroups}
                        edit={props.edit}
                        handleOnChange={props.onSelectChange}
                        value={source}
                      />
                    </Col>
                    ) : (
                    <>
                      <label className={'mt-25'}>{t(`label-filter`)}</label>
                      {props.selected.extendedFilter && props.selected.source && renderExtendedFilterGroup(props.selected.source.id)}
                      { props.edit &&
                      <Col md={12} lg={12} xs={12} className="mt-25" style={{textAlign: "left"}}>
                        <Dropdown onSelect={props.onAddFilterGroup}>
                          <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                            {t('button-addFilterGroup')}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey="OR">OR</Dropdown.Item>
                            <Dropdown.Item eventKey="AND">AND</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      }
                    </>
                    )}
                </Row>
              </Tab>
              <Tab eventKey="information" title={t('synchronisation-modal-information')}>
                <Row className='mt-25'>
                  <Col md={12} lg={6}>
                    <label>{t('input-label-createdAt')}</label>
                    <p style={{fontWeight: "bold"}} >{moment(props.selected.createdAt).format('YYYY-MM-DD')}</p>
                  </Col>
                  <Col md={12} lg={6}>
                    <label>{t('input-label-creator')}</label>
                    <p style={{fontWeight: "bold"}} >{props.selected.owner}</p>
                  </Col>
                </Row>
                {props.selected.synchronisation && (
                <Row className='mt-25'>
                  <Col md={12} lg={6}>
                    <label>{t('input-label-updatedAt')}</label>
                    <p style={{fontWeight: "bold"}} >{moment(props.selected.synchronisation.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</p>
                  </Col>
                  <Col md={12} lg={6}>
                    <label>{t('input-label-state')}</label>
                    <p style={{fontWeight: "bold"}} >{t(`sync-state-${props.selected.synchronisation.state}`)}</p>
                  </Col>
                  <Col md={12} lg={12}>
                    <label>{t('input-label-note')}</label>
                    <p style={{fontWeight: "bold"}} >{t(`${props.selected.synchronisation.message}`)}</p>
                  </Col>
                </Row>
                )}
              </Tab>
              <Tab eventKey="blacklist" title={t('synchronisation-modal-blacklist')}>
                <Row className='mt-25'>
                  <Col md={12} lg={12} xs={12} className="mt-25">
                    <ListGroup variant="flush">
                      <ListGroup.Item key={`listGroup-blacklist-header`}>
                        <Row>
                          <Col md={8} lg={8} xs={8} style={{textAlign: "left"}}>
                            <b>{t('input-label-displayName')}</b>                  
                          </Col>
                          <Col md={4} lg={4} xs={4} style={{textAlign: "right"}}>
                            <b>{t('input-label-delete')}</b>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      {props.selected.blacklistFilter && props.selected.blacklistFilter.map((element)=>(
                        <ListGroup.Item key={element.id}>
                          <Row>
                            <Col md={8} lg={8} xs={8} style={{textAlign: "left"}}>
                              {element.displayName}
                            </Col>
                            <Col md={4} lg={4} xs={4} style={{textAlign: "right"}}>
                              {props.actions.includes('modify_tasks') && 
                                <IconButton icon={faTrashAlt} color={'#646464'} tooltip={t('tooltip-delete')} id={`IconButton-edit-${element.id}`} key={`IconButton-edit-${element.id}`} placement={'top'} onClick={()=>props.onSelectUserRemove(element.id,'blacklistFilter')}/>
                              }
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Col>
                  <Col md={12} lg={12} xs={12} className="mt-25" style={{textAlign: "left"}}>
                    {props.actions.includes('modify_tasks') && 
                      <Button 
                        variant="outline-primary"
                        onClick={()=>handleOnClickAddUser('exclude')}
                      >
                        <FontAwesomeIcon icon={faPlus} style={{marginRight: 7}}/>
                        {t('button-addUser')}
                      </Button>
                    }
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="whitelist" title={t('synchronisation-modal-whitelist')}>
                <Row className='mt-25'>
                  <Col md={12} lg={12} xs={12} className="mt-25">
                    <ListGroup variant="flush">
                      <ListGroup.Item key={`listGroup-whitelist-header`}>
                        <Row>
                          <Col md={8} lg={8} xs={8} style={{textAlign: "left"}}>
                            <b>{t('input-label-displayName')}</b>                  
                          </Col>
                          <Col md={4} lg={4} xs={4} style={{textAlign: "right"}}>
                            <b>{t('input-label-delete')}</b>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      {props.selected.whitelistFilter && props.selected.whitelistFilter.map((element)=>(
                        <ListGroup.Item key={element.id}>
                          <Row>
                            <Col md={8} lg={8} xs={8} style={{textAlign: "left"}}>
                              {element.displayName}
                            </Col>
                            <Col md={4} lg={4} xs={4} style={{textAlign: "right"}}>
                              {props.actions.includes('modify_tasks') && 
                                <IconButton icon={faTrashAlt} color={'#646464'} tooltip={t('tooltip-delete')} id={`IconButton-edit-${element.id}`} key={`IconButton-edit-${element.id}`} placement={'top'} onClick={()=>props.onSelectUserRemove(element.id,'whitelistFilter')}/>
                              }
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Col>
                  <Col md={12} lg={12} xs={12} className="mt-25" style={{textAlign: "left"}}>
                    {props.actions.includes('modify_tasks') && 
                      <Button 
                        variant="outline-primary"
                        onClick={()=>handleOnClickAddUser('include')}
                      >
                        <FontAwesomeIcon icon={faPlus} style={{marginRight: 7}}/>
                        {t('button-addUser')}
                      </Button>
                    }
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="preview" title={t('synchronisation-modal-preview')}>
                <Row className='mt-25' style={{overflowY: 'auto', overflowX: 'hidden', maxHeight: '60vh'}}>
                  <Col md={12} lg={12} xs={12} className="mt-25">
                    <ListGroup variant="flush">
                      <ListGroup.Item key={`listGroup-preview-header`}>
                        <Row>
                          <Col md={6} lg={6} xs={12} style={{textAlign: "left"}}>
                            <b>{t('input-label-displayName')}</b>                  
                          </Col>
                          <Col md={6} lg={6} xs={12} style={{textAlign: "left"}}>
                            <b>{t('input-label-userType')}</b>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      {previewUsers && previewUsers.result.map((element)=>(
                        <ListGroup.Item key={element.id} style={getPreviewUsersStyle(element.id)}>
                          <Row>
                            <Col md={6} lg={6} xs={12} style={{textAlign: "left"}}>
                              {element.displayName}                            
                            </Col>
                            <Col md={6} lg={6} xs={12} style={{textAlign: "left"}}>
                              {element.userType}
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                      {previewUsers && previewUsers.nextLink && (
                        <ListGroup.Item key={`listGroup-preview-nextlink`}>
                          <Row>
                            <Col style={{textAlign: "center"}}>
                              <Button 
                                variant="outline-primary"
                                onClick={handleOnClickNextLink}
                              >
                                {t('button-more')}
                              </Button>                           
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      )}
                    </ListGroup>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
            <SelectUserModal
              controlId='blacklistFilter'
              edit={modalEditExclude}
              setEdit={setModalEditExclude}
              modalOpen={modalOpenExclude}
              onModalClose={setModalOpenExclude}
              onModalSave={handleOnSaveAddUser}
            />
            <SelectUserModal
              controlId='whitelistFilter'
              edit={modalEditInclude}
              setEdit={setModalEditInclude}
              modalOpen={modalOpenInclude}
              onModalClose={setModalOpenInclude}
              onModalSave={handleOnSaveAddUser}
            />
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Col style={{textAlign: "left"}}>
            {props.actions.includes('delete_tasks') &&
              <Button style={{margin:"5px"}} variant="secondary" onClick={()=>props.onModalRemove(false)}>{t('button-delete')}</Button>
            }
          </Col>
          <Col style={{textAlign:"right"}}>
            <Button style={{margin:"5px"}} variant="secondary" onClick={()=>props.onModalClose(false)}>{t('button-close')}</Button>
            {props.actions.includes('modify_tasks') && <Button style={{margin:"5px"}} variant="primary" onClick={()=>props.onModalSave(false)}>{t('button-save')}</Button>}
          </Col>
        </Modal.Footer>
      </Modal>
  )
}

export default SynchronisationModal