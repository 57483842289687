import React from 'react'
import { useDispatch } from 'react-redux'
import { toggleSidebar } from '../../redux/actions'
import { Image, Navbar, Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { IconBurger } from '../../assets/icons'
import { getAccessToken } from '../../utils/common';
import defaultAvatar from './images/default-user.jpg'
import defaultLogo from './images/Logo2-DynamicGroupSync.png'
import { useTranslation } from 'react-i18next'

const Header = ({identity, tenant, roleAssignments, logo, avatar}) => {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const style = {
    header: {
      zIndex: 1050,
      position: 'sticky',
      top:0
    },
    headerLogo: {
      height: 40,
      display: 'block',
      width: 'auto'
    },
    avatar: {
      flexWrap: 'nowrap'
    }
  }

  const handleOnRenderRoleAssignments = (userRoles) => {
    if( Array.isArray(userRoles) && userRoles.length > 0) {
      if ((userRoles.filter(x => x.value === 'Tenant.Owner')).length > 0)
        return 'label-administrator'
      if ((userRoles.filter(x => x.value === 'Tasks.Write')).length > 0)
       return 'label-contributor'
      if ((userRoles.filter(x => x.value === 'Tasks.Reader')).length > 0)
       return 'label-reader'
    }
  }

  if(!identity || !tenant || tenant === 'Unauthorized' || !getAccessToken() )
    return null

  return(
    <header style={{...style.header}}>
      <Navbar collapseOnSelect expand="lg" sticky="top" bg="primary" style={{height: '60px'}} >
        <Container fluid>
          <Navbar.Brand >
            <div className={'d-flex align-items-center'}>
              <IconBurger
                onClick={()=>dispatch(toggleSidebar())}
                fill="#fff"
              />
              <a style={{marginLeft: "20px"}} href={`${window._env_.REACT_APP_SERVICE_WEB}/`}><Image height={40} width={'auto'} style={{...style.headerLogo}} src={logo ? logo : defaultLogo} alt="FirstAttribute AG" /></a>
            </div>
          </Navbar.Brand>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              {renderAccountAvatar(identity, avatar, t, handleOnRenderRoleAssignments, roleAssignments)}
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header

function renderAccountAvatar(identity, avatar, t, handleOnRenderRoleAssignments, roleAssignments) {
  return <OverlayTrigger
    placement="bottom"
    overlay={<Tooltip id="button-tooltip-avatar">{identity.account.userName}</Tooltip>}
  >
    {({ ref, ...triggerHandler }) => (
      <Row {...triggerHandler} style={{display: 'flex', flexWrap: 'nowrap' }}>
        <Col md={4}>
          <Image ref={ref} alt={identity.account.name} style={{ width: '44px', height: '44px', margin: "5px" }} src={avatar ? avatar : defaultAvatar} roundedCircle />
        </Col>
        <Col style={{ paddingLeft: 0, textAlign: "left" }}>
          <Row style={{ whiteSpace: 'nowrap' }}><span style={{ color: "#fff", marginTop: "5px" }}>{identity.account.name}</span></Row>
          <Row style={{ whiteSpace: 'nowrap' }}><span style={{ color: "#6c757d", fontSize: '.7rem' }}>{t(handleOnRenderRoleAssignments(roleAssignments))}</span></Row>
        </Col>
      </Row>
    )}
  </OverlayTrigger>
}
