import axios from 'axios';
import { Buffer } from 'buffer';

export const getUser = () => {
  const userStr = localStorage.getItem('iam.user')
  if (userStr) return JSON.parse(userStr)
  else return null
}

export const verifyToken = async () => {
  var token = localStorage.getItem('iam.access_token')
  if(token){
    var auth = new Buffer.from(window._env_.REACT_APP_SERVICE_API_USER + ":" + window._env_.REACT_APP_SERVICE_API_PASSWORD).toString("base64")
    var config = {
      method: 'get',
      url: `${window._env_.REACT_APP_SERVICE_WEB}/login/oauth2/authorize/${token}/verify`,
      headers: {
        Authorization: 'Basic ' + auth,
      }
    };
    axios(config)
    .then(function (response) {
      if(!response.data.auth_token)
        killSession()
    })
    .catch(function (error) {
      killSession()
    });
  }
  else{
    return null
  }
}

export const getAccessToken = () => {
  return localStorage.getItem('iam.access_token') || null
}

export const getRefreshToken = () => {
  return localStorage.getItem('iam.refresh_token') || null
}
 
export const removeSession = () => {
  localStorage.removeItem('iam.access_token')
  localStorage.removeItem('iam.refresh_token')
  localStorage.removeItem('iam.tenant')
  localStorage.clear()
}

export const setAccessToken = (token) => {
  if(token) {
    localStorage.setItem('iam.access_token', token)
  }
}

export const setRefreshToken = (token) => {
  if(token)
    localStorage.setItem('iam.refresh_token', token)
}

export const setTenant = (tenant) => {
  if(tenant)
    localStorage.setItem('iam.tenant', tenant)
}

export const getTenant = () => {
  return localStorage.getItem('iam.tenant') || null
}

export const killSession = () => {
  removeSession()
  window.location.href = '/expired'
}