import { SET_LOADING, TOGGLE_SIDEBAR, SET_NOTIFICATION } from "../constants/action-types";

export function toggleSidebar(){
  return (dispatch) => {
    dispatch({type:TOGGLE_SIDEBAR})
  }
}

export function startLoading() {
  return (dispatch) => {
    dispatch({type: SET_LOADING, payload: true})
  }
}

export function hideNotification() {
  return (dispatch) => {
    dispatch({type: SET_NOTIFICATION, payload: null})
  }
}

export function showNotification(payload) {
  return (dispatch) => {
    dispatch({type: SET_NOTIFICATION, payload: payload})
  }
}