import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Select from 'react-select'
import { withTranslation } from 'react-i18next'
import IconButton from '../iconButton'
import { faTrash, faCheck, faEdit } from '@fortawesome/free-solid-svg-icons'
import operating from './linkFilter.json'
import relatedFilters from './relatedFilter.json'

const InputFilter = (props) => {
  const {t} = props
  const [ schemaExtensionsOperators, setSchemaExtensionsOperators ] = useState([])
  const [ linkExtensionsOperators, setSLinkExtensionsOperators ] = useState(operating)
  const [ edit, setEdit ] = useState(false)
  const [ data, setData ] = useState({
    link: '',
    attribute: '',
    operator: '',
    value: '',
    label: '',
    linkError: null,
    attributeError: null,
    operatorError: null,
    valueError: null
  })

  useEffect(() => {
    if(props.value){
      var filter = props.value
      var searchString = filter.substring(filter.indexOf('\'')+1,filter.lastIndexOf('\''))
      filter = filter.substring(0,filter.indexOf('\''))
      filter = filter.replaceAll("(",",")
      filter = filter.replaceAll(")",",")
      filter = filter.replaceAll(' ',",")
      filter = filter.split(',')
      parseFilter(filter, searchString)
    }
  }, [props.value, props.schemaExtensions])

  const parseFilter = (filter, search) => {
    var attribute = ''
    var operator = ''
    var label = ''
    var link = ''
    filter.forEach(element => {
      if((props.schemaExtensions.filter(x => x.name === element.trim())).length > 0){
        attribute = props.schemaExtensions.filter(x => x.name === element.trim())
        setSchemaExtensionsOperators(props.schemaExtensions.filter(x => x.name === element.trim())[0].operators)
        label = attribute[0].label
        attribute = attribute[0].name
      } 
      else if((operating.filter(x => x.name === element.trim())).length > 0){
        setSLinkExtensionsOperators(operating)
        link = operating.filter(x => x.name === element.trim())
        link = link[0].name
      }
      else if((relatedFilters.filter(x => x.name === element.trim())).length > 0){
        setSLinkExtensionsOperators(relatedFilters)
        link = relatedFilters.filter(x => x.name === element.trim())
        link = link[0].name
      }
      else if (element.trim().length > 0) {
        operator = element.trim()
      }
    });
    setData({
      attribute,
      label,
      operator,
      link,
      value: search
    })
  }
 
  const createFilter = () => {
    var filter = ""
    if(props.index === 0){
      if(data.operator === 'startsWith' || data.operator === 'endsWith' || data.operator === 'contains' ){
        filter = `${data.operator}(${data.attribute},'${data.value}') `
      }
      else if(data.operator === 'eqNull' ){
        filter = `${data.attribute} eq 'null' `
      }
      else {
        filter = `${data.attribute} ${data.operator} '${data.value}' `
      }

      if(props.filterGroupIndex > 0)
        filter = `${data.link} ${filter}`
    }
    else {
      if(data.operator === 'startsWith' || data.operator === 'endsWith' || data.operator === 'contains' ){
        filter = `${data.link} ${data.operator}(${data.attribute},'${data.value}') `
      }
      else if(data.operator === 'eqNull' ){
        filter = `${data.link} ${data.attribute} eq 'null' `
      }
      else {
        filter = `${data.link} ${data.attribute} ${data.operator} '${data.value}' `
      }
    }
    return filter
  }

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnAttributeSelectChange = (e) => {
    const { name, operators, label } = e
    setSchemaExtensionsOperators(operators)
    setData(prevData => ({
      ...prevData,
      attribute: name,
      label: label,
      operator: operators[0].name
    }))
  }

  const handleOnLinkSelectChange = (e) => {
    const { name } = e
    setData(prevData => ({
      ...prevData,
      link: name
    }))
  }

  const handleOnOperatorSelectChange = (e) => {
    const { name } = e
    setData(prevData => ({
      ...prevData,
      operator: name
    }))
  }

  const validateFilter = () => {
    let errors = {
      linkError: null,
      attributeError: null,
      operatorError: null,
      valueError: null
    }
    let vailid = true

    if(data.value === '' || data.value.indexOf('<') > 0 || data.value.indexOf('>') > 0 || data.value === null){
      errors.valueError = true
      vailid = false
    }

    if(data.attribute === '' || data.attribute === null){
      errors.attributeError = true
      vailid = false
    }

    if(data.operator === '' || data.operator === null){
      errors.operatorError = true
      vailid = false
    }

    if((data.link === '' || data.link === null) && props.index !== 0){
      errors.linkError = true
      vailid = false
    }
    setData(prevData => ({
      ...prevData,
      linkError: errors.linkError,
      attributeError: errors.attributeError,
      operatorError: errors.operatorError,
      valueError: errors.valueError
    }))
    return vailid
  }

  const handleOnClickSave = async() => {
    if(validateFilter()){
      var filter = await createFilter()
      props.onSave(filter, props.index, props.filterGroupIndex)
      setEdit(false)
    }
  }

  const handleOnClickRemove = () => {
    props.onDelete(props.index, props.filterGroupIndex)
  }

  return(
    <Row>
      <Col md={2} lg={2}>
        <div style={{ marginTop: "10px" }}>
          <label>{t(`input-label-andOr`)}</label>
          {edit ?
            <div>
              <Select
                isSearchable={true}
                name="link"
                options={linkExtensionsOperators}
                isOptionSelected={option => (option.name === data.link)}
                value={linkExtensionsOperators.find(x => x.name === data.link)}
                isDisabled={props.index < 1}
                onChange={handleOnLinkSelectChange}
              />
            </div>
            :
            <p style={{ fontWeight: "bold" }}>{data.link}</p>}
        </div>
      </Col>
      <Col md={4} lg={4}>
        <div style={{ marginTop: "10px" }}>
          <label>{t(`input-label-attribute`)}</label>
          {edit ?
            <div>
              <Select
                isSearchable={true}
                name="attribute"
                value={props.schemaExtensions.find(x => x.name === data.attribute)}
                options={props.schemaExtensions}
                getOptionLabel={option => `${option.label}`}
                isOptionSelected={option => (option.name === data.attribute)}
                onChange={handleOnAttributeSelectChange}
              />
            </div>
            :
            <p style={{ fontWeight: "bold" }}>{data.label}</p>}
        </div>
      </Col>
      <Col md={2} lg={2}>
        <div style={{ marginTop: "10px" }}>
          <label>{t(`input-label-operator`)}</label>
          {edit ?
            <div>
              <Select
                isSearchable={true}
                name="operator"
                value={schemaExtensionsOperators.find(x => x.name === data.operator)}
                options={schemaExtensionsOperators}
                getOptionLabel={option => `${option.name}`}
                isOptionSelected={option => (option.name === data.operator)}
                onChange={handleOnOperatorSelectChange}
              />
            </div>
            :
            <p style={{ fontWeight: "bold" }}>{data.operator}</p>}
        </div>
      </Col>
      <Col md={3} lg={3}>
        <div style={{ marginTop: "10px" }}>
          <label>{t(`input-label-value`)}</label>
          {edit ?
            <div>
              <Form.Group controlId={'value'}>
                <Form.Control type='text' onChange={handleOnChange} defaultValue={data.value} isInvalid={props.valueError}/>
              </Form.Group>
            </div>
            :
            <p style={{ fontWeight: "bold" }}>{data.value}</p>}
        </div>
      </Col>
      <Col md={1} lg={1} style={{justifyContent: "center", textAlign: "left",  alignItems: "center", display: "flex"}}>
      {edit ? 
        <>
          <IconButton icon={faCheck} color={'#00b252'} tooltip={t('tooltip-save')} id={`inputFilter-${props.index}-save`} key={`inputFilter-${props.index}-save`} placement={'top'} onClick={handleOnClickSave}/>
          <IconButton icon={faTrash} color={'#a61d04'} tooltip={t('tooltip-delete')} id={`inputFilter-${props.index}-delete`} key={`inputFilter-${props.index}-delete`} placement={'top'} onClick={handleOnClickRemove}/>
        </>
      :
        <>{props.actions.includes('modify_tasks') && 
          <IconButton icon={faEdit} color={'#646464'} tooltip={t('tooltip-edit')} id={`inputFilter-${props.index}-edit`} key={`inputFilter-${props.index}-edit`} placement={'top'} onClick={()=>setEdit(true)}/>
        }</>
      }
      </Col>
      <Col md={12} lg={12}>
        <hr />
      </Col>
    </Row>
  )
}

export default withTranslation()(InputFilter)