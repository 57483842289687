import React from 'react'
import { Form } from 'react-bootstrap'

const InputField = (props) => {
  return(
    <div style={{marginTop: "10px"}}>
      <label>{props.label}{props.required && <span style={{color: 'var(--bs-warning)'}}> *</span>}</label>
      {props.edit ?
      <div>
        <Form.Group controlId={props.controlId} >
          {props.type === 'textarea' && (<Form.Control as={props.type} onChange={props.handleOnChange} placeholder={props.placeholder} defaultValue={props.value} isInvalid={props.isInvalid}/>)}
          {(props.type === 'text' || props.type === 'number') && (<Form.Control type={props.type} onChange={props.handleOnChange} placeholder={props.placeholder} defaultValue={props.value} isInvalid={props.isInvalid}/>)}
        </Form.Group>
      </div> 
      :
      <p style={{fontWeight: "bold"}} >{props.value}</p>
      }
    </div>
  )
}

export default (InputField)