import React from 'react'
import { PulseLoader } from 'react-spinners'
import { useTranslation } from 'react-i18next'

const LoaderCover = ({loading}) => {
  const {t} = useTranslation()

  if(loading !== true)
    return null

  return(
    <div style={{textAlign: 'center', margin: 30}}>
      <PulseLoader 
        color="#002d73"
      />
      <p className='fs-4'>{t(`label-loading`)}</p>
    </div>
  )
}

export default (LoaderCover);