import React, { useEffect, useState } from 'react'
import { Row, Col, ListGroup } from 'react-bootstrap'
import { IconButton } from '../'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faStop, faEdit, faCheckCircle, faPause, faExclamationCircle, faTimesCircle, faSync, faInfo } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const SynchronisationItem = (props) => {
  const {t} = useTranslation()

  const [ source, setSource ] = useState(null)
  const [ target, setTarget ] = useState(null)
  const [ synchronisation, setSynchronisation ] = useState(null)

  const colors = {
    error: 'red',
    warn: 'darkorange',
    success: 'green',
    pending: 'blue'
  }

  const stateIcon = {
    true: faStop,
    false: faPause
  }

  const syncIcon = {
    error: faTimesCircle,
    warn: faExclamationCircle,
    success: faCheckCircle,
    pending: faSync
  }

  const stateText = {
    true: "tooltip-stop",
    false: "tooltip-pause"
  }

  useEffect(() => {
    setSource(props.source)
    setTarget(props.target)
  }, [])

  useEffect(() => {
    setSynchronisation(props.synchronisation)
  }, [props])

  return(
    <ListGroup.Item>
      <Row>
        <Col lg={4} md={4} onDoubleClick={()=>props.onOpen(props.taskId)} style={{cursor: 'pointer'}}>
          <span>{target && `${target.displayName}`}</span>
        </Col>
        <Col xs={false} lg={3} md={3}>
          <span>{source && `${source.displayName}`}</span><br />
        </Col>
        <Col lg={3} md={3}>
          {synchronisation && (
            <div>
              <div>
                <FontAwesomeIcon icon={syncIcon[synchronisation.state]} style={{ color: colors[synchronisation.state] }} fixedWidth />
                <span style={{ color: colors[synchronisation.state] }}>{t(`sync-state-${synchronisation.state}`)}</span>
              </div>
              <div>
                <span>{moment(synchronisation.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</span>
              </div>
            </div>
          )}
        </Col>
        <Col lg={2} md={2} style={{margin:"auto"}}>
          {props.actions.includes('start_tasks') && <IconButton icon={faPlay} color={'#646464'} tooltip={t('tooltip-play')} id={'play'} key={'play'} placement={'top'} size="lg" onClick={()=>props.onClickRun(props.taskId)}/>}
          {props.actions.includes('modify_tasks') && <IconButton icon={stateIcon[props.active]} color={'#646464'} tooltip={t(stateText[props.active])} id={'stop'} key={'stop'} placement={'top'} size="lg" onClick={()=>props.onClickState(props.taskId)} />}
          <IconButton icon={props.actions.includes('modify_tasks') ? faEdit : faInfo} color={'#646464'} tooltip={props.actions.includes('modify_tasks') ? t('tooltip-edit') : t('tooltip-info') } id={'edit'} key={'edit'} placement={'top'} size="lg" onClick={()=>props.onOpen(props.taskId)}/>
        </Col>
      </Row>
    </ListGroup.Item>
  )
}

export default SynchronisationItem