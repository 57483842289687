import React from 'react'
import { Form } from 'react-bootstrap'

const InputCheck = (props) => {
  const onChangeCheck = (e) => {
    props.handleOnChange({target:{id: props.controlId, value: e.target.checked}})
  }

  return(
    <div style={{marginTop: "10px"}}>
      <label>{props.label}</label>
      {props.edit ?
      <div>
        <Form.Check 
          type="switch"
          id={props.controlId}
          label={props.value}
          checked={props.checked}
          onChange={onChangeCheck}
        />
      </div> 
      :
      <p style={{fontWeight: "bold"}} >{props.value}</p>
      }
    </div>
  )
}

export default (InputCheck)