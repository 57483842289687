import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut, signIn } from '../../redux/actions';
import { Button } from 'react-bootstrap'
import authService from '../../utils/authHelper';
import logo from './images/microsoft.png'

const SignInButton = () => {
  const identity = useSelector((state) => state.identity);
  const dispatch = useDispatch();

  const styles = {
    btnImage : {
      height: "20px",
      margin: "5px"
    }
  }
  const onClickHandler = () => {
    dispatch(identity ? signOut() : signIn());
  };

  const buttonText = identity ? `Sign out from ${authService.serviceName}` : `Sign in with ${authService.serviceName}`;

  return (
    <Button variant="outline-primary" onClick={onClickHandler}>
      <img src={logo} alt="Login with Microsoft" style={styles.btnImage} />
      <span >{buttonText}</span>
    </Button>
  );
};

export default SignInButton;