import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Container, Row, Col, Image, Form, Card } from 'react-bootstrap'
import { SignInButton } from '../'
import ImageSection from './imageSection'
import { withTranslation } from 'react-i18next'

import './login.css'
import logo from './images/DynamicSynclogo.png'
import { useNavigate } from 'react-router-dom';

const Login = (props) => {

  const {t} = props
  const navigate = useNavigate();
  const identity = useSelector((state) => state.identity)
  const tenant = useSelector((state) => state.tenant)
  const access_token = useSelector((state) => state.access_token)
  const [ query, setQuery ] = useState()

  useEffect(()=>{
    if(props.location){
      const params = new URLSearchParams(props.location.search)
      setQuery(params.get("state"))
    }

  },[props.location])

  if(identity && tenant && access_token)
    navigate('/dashboard')

  return(
    <Row className={"no-gutter"} >
      <ImageSection />
      <Col md={5} lg={5} style={{background: "#fbfbfb"}}>
        <div className={"login d-flex align-items-center py-5"}>
          <Container>
            <Row>
              <Col className={"mx-auto"} md={12} lg={12} >
                <div style={{ justifyContent: 'center', display: 'flex'}}>
                  <Image fluid src={logo} alt="Firstattribute AG" style={{maxWidth : '250px'}} />
                </div>
                { query === 'congratulation' ? 
                  <div>
                    <h3 style={{textAlign: 'center'}}>{t('congratulations-headline')}</h3>
                    <h5 style={{textAlign: 'center'}}>{t('congratulations-content')}</h5>
                  </div>
                :
                  <div>
                    <h5 style={{textAlign: 'center'}}>{t('login-content')}</h5>
                    <p style={{textAlign: 'center'}}>{t('login-contentText')}</p>
                  </div>
                }
                <Card.Body style={{textAlign: 'center'}} >
                  <Form>
                    <SignInButton />
                  </Form>
                </Card.Body>
              </Col>
            </Row>
          </Container>
        </div>
      </Col>
    </Row>
  )
}

export default (withTranslation()(Login))