import React, { useState }from 'react';
import AsyncSelect from 'react-select/async';

function createRequestFilter(filter) {
  var result = ""
  if (filter) {
    for (const key in filter) {
      if (filter[key])
        result += `&search=OR(${key}:${filter[key]})`
    }
  }
  return result
}

const InputSelectUser = (props) => {

  const [ input, setInput ] = useState(null)

  const onChangeFunction = (e) => {
    var obj = {
      id:e.id,
      displayName: e.displayName,
      controlId: props.controlId
    }
    props.handleOnChange(obj);
  }

  const onChangeInput = (newInput) => {
    setInput(newInput);
    return newInput;
  }

  const loadOptions = () => {
    const filter = createRequestFilter({ displayName: input, mail: input })
    return(props.options(filter));
  };

  return(
    <div style={{marginTop: "10px"}}>
      <label>{props.label}</label>
      {props.edit ?
      <div>
        <AsyncSelect
          loadOptions={loadOptions}
          cacheOptions 
          defaultOptions
          onChange={onChangeFunction}
          onInputChange={onChangeInput}
          defaultInputValue={props.value}
          placeholder={props.placeholder && props.placeholder}
          getOptionLabel={option => `${option.displayName} (${option.mail})`}
          isOptionSelected={option => props.value && (option.mail === props.value.mail)}
        />
      </div> 
      :
      <p style={{fontWeight: "bold"}} >{props.value}</p>
      }
    </div>
  )
}

export default (InputSelectUser)