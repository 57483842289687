import axios from 'axios';
import { Buffer } from 'buffer';
import { getAccessToken, setAccessToken, getRefreshToken, getTenant, removeSession } from './common';
import { showNotification } from '../redux/actions';
import { store } from '../redux/store/index';

axios.interceptors.request.use(
  (config) => {
    if(config.headers.hasOwnProperty('Authorization'))
      if(config.headers.Authorization.includes('Basic '))
        return config;
    const accessToken = getAccessToken()
    if (accessToken) {
      config.headers["Authorization"] = 'Bearer ' + accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    let refreshToken = getRefreshToken();
    if (refreshToken && error.response.status === 401 && originalRequest._retry !== true) {
      originalRequest._retry = true;
      var auth = new Buffer.from(window._env_.REACT_APP_SERVICE_API_USER + ":" + window._env_.REACT_APP_SERVICE_API_PASSWORD).toString("base64")
      var config = {
        method: 'get',
        url: `${window._env_.REACT_APP_SERVICE_WEB}/login/oauth2/authorize/${refreshToken}/refresh`,
        headers: { 
          Authorization: 'Basic ' + auth
        }
      };
      return axios(config)
        .then((res) => {
          if (res.status === 200) {
            setAccessToken(res.data.auth_token)
            console.log("Access token refreshed!");
            return axios(originalRequest);
          }
          if (res.status === 401) {
            removeSession()
          }
        }).catch(err => {
          removeSession()
        });
    }
    return Promise.reject(error);
  }
);

export const fetchAllowedActions = async (page) => {
  const tenantId = getTenant()
  if(tenantId){
    var url = `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant/users/me/allowedActions?tenantId=${tenantId}`
    if(page)
      url = `${url}&page=${page}`
    var config = {
      method: 'get',
      url: url
    };
    var result = await axios(config)
    return result.data
  }
  else
    return null
}

export const updateTenant = async (dataObject) => {
  const tenantId = getTenant()
  if(tenantId){
    var data = JSON.stringify(dataObject);
    var config = {
      method: 'patch',
      url: `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant?tenantId=${tenantId}`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    return await axios(config)
  }
  else
    return null
}

export const createApplication = async (msalAccessToken, tenantId, tenant) => {
  var data = JSON.stringify({msalAccessToken: msalAccessToken, manager: tenant.manager, name: tenant.name});
  var config = {
    method: 'post',
    url: `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant/application?tenantId=${tenantId}`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  return await axios(config)
}

export const createTask = async (object) => {
  const tenantId = getTenant()
  if(tenantId){
    try{
      var data = JSON.stringify({
        "source": object.source,
        "target": object.target,
        "description": object.description,
        "owner": object.owner,
        "active":object.active,
        "extendedFilter": object.extendedFilter,
        "blacklistFilter": object.blacklistFilter,
        "whitelistFilter": object.whitelistFilter
      });
  
      var config = {
        method: 'post',
        url: `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant/task?tenantId=${tenantId}`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      var result = await axios(config).catch(err => {
        if (err.response.status === 500) {
          throw new Error(`error-task-present`);
        }
        throw err;
      });
      return result.data
    }
    catch (err) {
      return err
    }
  }
  else
    return null
}

export const updateTask = async (object) => {
  const tenantId = getTenant()
  if(tenantId){
    var data = JSON.stringify({
      "source": object.source,
      "target": object.target,
      "description": object.description,
      "_id": object._id,
      "active":object.active,
      "extendedFilter": object.extendedFilter,
      "blacklistFilter": object.blacklistFilter,
      "whitelistFilter": object.whitelistFilter
    });

    var config = {
      method: 'patch',
      url: `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant/task?tenantId=${tenantId}`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    var result = await axios(config).catch(err => {
      if (err.response.status === 403) {
        store.dispatch(showNotification({status: 403, type: 'warning', message: err.response.data.message}))
        throw new Error(err.response.data.message);
      }
      else
        throw err;
    });
    return result.data
  }
  else
    return null
}

export const fetchTasks = async (limit, skip, search) => {
  const tenantId = getTenant()
  if(tenantId){
    var url = `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant/task?tenantId=${tenantId}`
    if(limit)
      url = `${url}&limit=${limit}`
    if(skip)
      url = `${url}&skip=${skip}`
    if(search)
      url = `${url}&search=${search}`
    
    var config = {
      method: 'get',
      url: url
    };
    var result = await axios(config)
    return result.data
  } 
  else
    return null
}

export const deleteTask = async (id) => {
  const tenantId = getTenant()
  if(tenantId){
    var config = {
      method: 'delete',
      url: `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant/task?tenantId=${tenantId}&id=${id}`
    };
    var result = await axios(config).catch(err => {
      if (err.response.status === 403) {
        store.dispatch(showNotification({status: 403, type: 'warning', message: err.response.data.message}))
        throw new Error(err.response.data.message);
      }
      else
        throw err;
    });
    return result.data
  }
  else
    return null
}

export const getAzureGroups = (search,filter) => {
  const tenantId = getTenant()
  if(tenantId){
    var url = `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant/groups?tenantId=${tenantId}`
    if(search)
      url = `${url}${search}`
    if(filter)
      url = `${url}${filter}`
    return new Promise((resolve, reject) => {
      var config = {
        method: 'get',
        url: url,
        headers: { 
          'Content-Type': 'application/json'
        }
      };

      axios(config)
      .then(function ({data}) {
        resolve(data.result);
      })
      .catch(function (error) {
        resolve();
      });
    });
  }
  else
    return null
}

export const getAzureUsers = (search) => {
  const tenantId = getTenant()
  if(tenantId){
    var url = `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant/users?tenantId=${tenantId}`
    if(search)
      url = `${url}&search=${search}`

    return new Promise((resolve, reject) => {
      var config = {
        method: 'get',
        url: url,
        headers: { 
          'Content-Type': 'application/json'
        }
      };

      axios(config)
      .then(function ({data}) {
        resolve(data.result);
      })
      .catch(function (error) {
        resolve();
      });
    });
  }
  else
    return null
}

export const startTasks = async (id) => {
  const tenantId = getTenant()
  if(tenantId){
    var config = {
      method: 'get',
      url: `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant/task/${id}/queue?tenantId=${tenantId}`
    };
    var result = await axios(config).catch(err => {
      if (err.response.status === 403) {
        store.dispatch(showNotification({status: 403, type: 'warning', message: err.response.data.message}))
        throw new Error(err.response.data.message);
      }
      else
        throw err;
    });
    return result.data
  } 
  else
    return null
}

export const fetchPreview = async (filter, nextLink) => {
  const tenantId = getTenant()
  var url = `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant/task/filterpreview?filterId=${filter.displayName}&filter=${filter.id}&tenantId=${tenantId}`
  if(nextLink)
    url += `&nextLink=${nextLink}`
  if(tenantId){
    var config = {
      method: 'get',
      url: url
    };
    var result = await axios(config)
    return result.data
  } 
  else
    return null
}

export const fetchSchemaExtensions = async (search, filter) => {
  const tenantId = getTenant()
  var url = `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant/schemaExtension?tenantId=${tenantId}`
  if(filter) {
    for (const [key, value] of Object.entries(filter)) {
      url += `&filter=${key}:${value}`
    }
  }
  if(search)
    url += `&search=${search}`
  if(tenantId){
    var config = {
      method: 'get',
      url: url
    };
    var result = await axios(config)
    return result.data
  } 
  else
    return null
}

export const createCustomSchemaExtension = async (object) => {
  const tenantId = getTenant()
  if(tenantId){
    var data = JSON.stringify({
      "name": object.name,
      "label": object.label,
    });
    var config = {
      method: 'post',
      headers: { 
        'Content-Type': 'application/json'
      },
      url: `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant/schemaExtension?tenantId=${tenantId}`,
      data : data
    };
    try {
      const result = await axios(config)
      return ({...result.data, ...{status: 'success'}})
    }
    catch (err) {
      if (err.response.status === 400) {
        return ({success: false, message: err.response.data.message, status: 'error'})
      }
      if (err.response.status === 500) {
        return ({success: false, message: err.response.data.error, status: 'error'})
      }
      else
        throw err;
    }
  }
  else
    return null
}

export const removeCustomSchemaExtension = async (name) => {
  const tenantId = getTenant()
  if(tenantId){
    var config = {
      method: 'delete',
      url: `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant/schemaExtension?tenantId=${tenantId}&name=${name}`
    };
    var result = await axios(config).catch(err => {
      if (err.response.status === 400) {
        store.dispatch(showNotification({status: 403, type: 'warning', message: err.response.data.message}))
        throw new Error(err.response.data.message);
      }
      else
        throw err;
    });
    return result
  }
  else
    return null
}

export const fetchNotificationSettings = async () => {
  const tenantId = getTenant()
  var url = `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant/notification?tenantId=${tenantId}`

  if(tenantId){
    var config = {
      method: 'get',
      url: url
    };
    var result = await axios(config)
    return result.data
  }
  else
    return null
}

export const createOrUpdateNotificationSettings = async (object) => {
  const tenantId = getTenant()
  if(tenantId){
    var data = JSON.stringify({
      "defaultMail": object.defaultMail
    });
    var config = {
      method: 'put',
      headers: { 
        'Content-Type': 'application/json'
      },
      url: `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant/notification?tenantId=${tenantId}`,
      data : data
    };
    try {
      const result = await axios(config)
      return ({...result.data, ...{status: 'success'}})
    }
    catch (err) {
      if (err.response.status === 400) {
        return ({success: false, message: err.response.data.message, status: 'error'})
      }
      if (err.response.status === 500) {
        return ({success: false, message: err.response.data.error, status: 'error'})
      }
      else
        throw err;
    }
  }
  else
    return null
}

export const fetchTasksInsights = async (filter) => {
  const tenantId = getTenant()
  if(tenantId){
    var url = `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant/task/insights?tenantId=${tenantId}`
    if(filter)
      url += filter
    var config = {
      method: 'get',
      url: url
    };
    var result = await axios(config)
    return result.data
  } 
  else
    return null
}

export const fetchMeProfilePhoto = async () => {
  const tenantId = getTenant()
  if(tenantId){
    var url = `${window._env_.REACT_APP_SERVICE_API}/${window._env_.REACT_APP_SERVICE_API_VERSION}/tenant/users/me/photo?tenantId=${tenantId}`
    var config = {
      method: 'get',
      url: url
    };
    var result = await axios(config)
    if(result.status === 200)
      return result.data
    return null
  } 
  else
    return null
}