import {NETWORK_ERROR, NETWORK_START, NETWORK_STOP, SET_IDENTITY, SET_ROLEASSIGNMENTS, SET_ALLOWEDACTIONS, SET_LOADING, TOGGLE_SIDEBAR, SET_TENANT, SET_AVATAR, SET_ACCESSTOKEN, SET_REFRESHTOKEN, SET_ADMIN, TASK_FETCH, TASK_CREATE, TASK_UPDATE, TASK_REMOVE, SET_NOTIFICATION } from '../constants/action-types'

const initialState = {
  loading: false,
  sidebar: false,
  networkRequests: 0,
  notification: null,
  identity: null,
  avatar: null,
  roleAssignments: [],
  allowedActions: [],
  tenant_admin: null,
  tenant: null,
  access_token: null,
  refresh_token: null,
  tasks: [],
  lastError: null
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATION:
      return { ...state, notification: action.payload }
    case NETWORK_ERROR:
      return { ...state, lastError: action.error };
    case NETWORK_START:
      return { ...state, networkRequests: state.networkRequests + 1 };
    case NETWORK_STOP:
      return { ...state, networkRequests: state.networkRequests - 1 };
    case SET_IDENTITY:
      return { ...state, identity: action.payload };
    case SET_AVATAR:
      return { ...state, avatar: action.payload };
    case SET_ROLEASSIGNMENTS:
      return { ...state, roleAssignments: action.payload };
    case SET_ALLOWEDACTIONS:
      return { ...state, allowedActions: action.payload };
    case SET_ADMIN:
      return { ...state, tenant_admin: action.payload };
    case SET_TENANT:
      return { ...state, tenant: action.payload };
    case SET_ACCESSTOKEN:
      return { ...state, access_token: action.payload };
    case SET_REFRESHTOKEN:
      return { ...state, refresh_token: action.payload };
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case TOGGLE_SIDEBAR:
      return { ...state, sidebar: !state.sidebar };
    case TASK_FETCH:
      return { ...state, tasks: action.result};
    case TASK_CREATE:
      var newArray = [...state.tasks];
      newArray.push(action.result);
      return { ...state, tasks: newArray};
    case TASK_UPDATE:
      const taskIndex = state.tasks.findIndex(element => element._id === action.result._id)
      let updateArray = [...state.tasks]
      updateArray[taskIndex] = action.result
      return { ...state, tasks: updateArray};
    case TASK_REMOVE:
      let removeArray = state.tasks.filter(element => element._id !== action._id)
      return { ...state, tasks: removeArray};
    default:
      return state;
  }
}

export default rootReducer;