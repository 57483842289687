import {NETWORK_START, NETWORK_STOP, NETWORK_ERROR, SET_IDENTITY, SET_AVATAR, SET_ROLEASSIGNMENTS, SET_ALLOWEDACTIONS, SET_TENANT, SET_ACCESSTOKEN, SET_REFRESHTOKEN, SET_ADMIN, SET_LOADING } from '../constants/action-types'
import authService from '../../utils/authHelper'
import tenantService from '../../utils/tenantHelper'
import registrationService from '../../utils/registrationHelper'
import { updateTenant, createApplication, fetchMeProfilePhoto, fetchAllowedActions } from '../../utils/requestHelper'

function Sleep(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
 }

export function initializeNetwork() {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: NETWORK_START });
      const identity = await authService.getAccessToken();
      dispatch({ type: SET_IDENTITY, payload: identity });
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
      dispatch({ type: SET_LOADING, payload: false });
    }
  };
}

export function signIn() {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: NETWORK_START });
      const identity = await authService.signIn();
      const response = await tenantService.signIn(identity.idToken, identity.tenantId);
      const tenant = response.tenant
      if(tenant.tenantState === 'authorized'){
        dispatch({ type: SET_TENANT, payload: tenant });
        dispatch({ type: SET_ACCESSTOKEN, payload: response.auth_token });
        dispatch({ type: SET_REFRESHTOKEN, payload: response.refresh_token });
        const actions = await fetchAllowedActions()
          if(actions.success)
        dispatch({ type: SET_IDENTITY, payload: identity });
        dispatch({ type: SET_ROLEASSIGNMENTS, payload: response.roleAssignments});
          dispatch({ type: SET_ALLOWEDACTIONS, payload: actions.pages})
        fetchMeProfilePhoto().then((avatar) => {
          dispatch({ type: SET_AVATAR, payload: avatar });
        })
      }
      else if(tenant.tenantState === 'unauthorized'){
        window.location.href = (`${window.location.origin}/unauthorized`);
      }
      else if(tenant.tenantState === 'pending'){
        let browserLanguage = localStorage.getItem('i18nextLng') || 'en'
        window.location.href = (`${window._env_.REACT_APP_REGISTRATION_URL}/${browserLanguage}/registration?tenant=${tenant.tenantId}&email_address=${tenant.manager}`);
      }
      else if(tenant.tenantState === 'verified'){
        window.location.href = (`${window.location.origin}/registration?tenatId=${tenant.tenantId}`);
      }
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
      dispatch({ type: SET_LOADING, payload: false });
    }
  };
}

export function registrationSignIn() {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: NETWORK_START });
      await registrationService.signIn();
      const identity = await registrationService.getIdentity();
      const response = await tenantService.signIn(identity.idToken, identity.tenantId);
      const tenant = response.tenant;
      if(tenant.tenantState === 'verified'){
        dispatch({ type: SET_TENANT, payload: tenant });
        dispatch({ type: SET_ACCESSTOKEN, payload: response.auth_token });
        dispatch({ type: SET_REFRESHTOKEN, payload: response.refresh_token });
        dispatch({ type: SET_ADMIN, payload: identity });
      }
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
      dispatch({ type: SET_LOADING, payload: false });
    }
  };
}

export function registrationSubmit(object, msalAccessToken) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: NETWORK_START });
      const tenantResult = await updateTenant(object)
      if(tenantResult.data.success === true){
        await Sleep(5000);
        const appResult = await createApplication(msalAccessToken, object.tenantId, object)
        const applicaion = appResult.data.appResult
        if(appResult.data.success === true){
          await Sleep(11800);
          await updateTenant({tenantState:'authorized', tenantId: object.tenantId})
          dispatch({ type: SET_ADMIN, payload: null });
          dispatch({ type: SET_TENANT, payload: null });
          dispatch({ type: SET_ACCESSTOKEN, payload: null });
          dispatch({ type: SET_IDENTITY, payload: null });
          localStorage.clear()
          window.location.href = `https://login.microsoftonline.com/${applicaion.tenantId}/adminconsent?client_id=${applicaion.clientId}&redirect_uri=${window._env_.REACT_APP_SERVICE_WEB}&state=congratulation` 
        }
      }
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
      dispatch({ type: SET_LOADING, payload: false });
    }
  };
}

export function signOut() {
  return (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    dispatch({ type: NETWORK_START });
    authService.signOut();
    tenantService.signOut();
    dispatch({ type: NETWORK_STOP });
    dispatch({ type: SET_IDENTITY, payload: null });
    dispatch({ type: SET_TENANT, payload: null });
    dispatch({ type: SET_ACCESSTOKEN, payload: null });
    dispatch({ type: SET_REFRESHTOKEN, payload: null });
    dispatch({ type: SET_ROLEASSIGNMENTS, payload: null });
    dispatch({ type: SET_ALLOWEDACTIONS, payload: null });
    dispatch({ type: SET_AVATAR, payload: null });
    dispatch({ type: SET_LOADING, payload: false });
  };
}