import React, { useState, useEffect } from 'react'
import { Modal, Button, Container, Row, Col } from 'react-bootstrap'
import { InputSelectUser } from '../'
import { getAzureUsers } from '../../utils/requestHelper'
import { useTranslation } from 'react-i18next'

const SelectUserModal = (props) => {
  const {t} = useTranslation()
  const [ selected, setSelected ] = useState()

  useEffect(() => {
    setSelected(null)
  }, [props])

  const handleOnChangeSelectUser = (e) => {
    const { id, displayName, controlId } = e
    setSelected({id,displayName,controlId})
  }

  if(props.modalOpen===false || !props.modalOpen)
    return null

  return(
      <Modal
        show={props.modalOpen}
        onHide={()=>props.onModalClose(false)}
        backdrop="static"
        keyboard={false}
        size='md'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('selectUser-modal-headline')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{fontSize: "1.0rem"}}>
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <InputSelectUser
                  key={`input-select-${props.controlId}`}
                  label={t('input-label-users')}
                  placeholder={t('placeholder-label-users')}
                  controlId={props.controlId}
                  options={getAzureUsers}
                  edit={true}
                  handleOnChange={handleOnChangeSelectUser}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Col style={{textAlign:"right"}}>
            <Button style={{margin:"5px"}} variant="secondary" onClick={()=>props.onModalClose(false)}>{t('button-close')}</Button>
            <Button style={{margin:"5px"}} variant="primary" onClick={()=>props.onModalSave(selected)}>{t('button-save')}</Button>
          </Col>
        </Modal.Footer>
      </Modal>
  )
}

export default SelectUserModal