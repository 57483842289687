import React from 'react'
import { Row, Col, ListGroup } from 'react-bootstrap'
import { IconButton } from '..'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const CustomAttributeListItem = ({element, actions, onRemoveItem}) => {
  const {t} = useTranslation()

  return(
    <ListGroup.Item>
      <Row>
        <Col lg={7} md={7}>
          <span>{element && `${element.name}`}</span>
        </Col>
        <Col xs={false} lg={3} md={3}>
          <span>{element && `${element.label}`}</span><br />
        </Col>
        <Col lg={2} md={2} style={{margin:"auto"}}>
          <IconButton icon={faTrashAlt} color={'#646464'} tooltip={t('tooltip-delete')} id={`IconButton-edit-${element.name}`} key={`IconButton-edit-${element.name}`} placement={'top'} onClick={()=>onRemoveItem(element.name)}/>
        </Col>
      </Row>
    </ListGroup.Item>
  )
}

export default CustomAttributeListItem