import { ClientAuthError, InteractionRequiredAuthError, UserAgentApplication } from 'msal'
import Identity from '../models/identity'
import config from '../config/Settings.json'

class RegistrationService {
  constructor(configuration) {
    this.signInOptions = {
      scopes: configuration.scopes
    };

    this.msalConfig = {
      auth: {
        authority: configuration.authority,
        clientId: configuration.clientId,
        redirectUri: window.location.origin
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
      }
    };

    this.msalClient = new UserAgentApplication(this.msalConfig);
  }

  get serviceName() { return 'Microsoft'; }

  async signIn() {
    try{
      const response = await this.msalClient.loginPopup(this.signInOptions);
      return new Identity(response);
    }
    catch (err) {
      console.log(err)
    }

  }

  signOut() {
    this.msalClient.logout();
  }

  async getIdentity() {
    const account = this.msalClient.getAccount();
    if (account) {
      try {
        const response = await this.msalClient.acquireTokenSilent(this.signInOptions);
        return new Identity(response);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          throw new Error(error);
        }
        if (error instanceof ClientAuthError) {
          if (error.errorCode === 'block_token_requests') {
            throw new Error(error);
          }
          console.warn('ClientAuthError: error code = ', error.errorCode);
        }
        throw error;
      }
    }
  }
}

var msal = {
  "authority": config.registration.authority,
  "clientId" : window._env_.REACT_APP_REGISTRATION_CLIENTID,
  "clientSecret" : window._env_.REACT_APP_REGISTRATION_CLIENTSECRET,
  "subscriptionId" : window._env_.REACT_APP_REGISTRATION_SUBSCRIPTION,
  "scopes": config.registration.scopes
}
const registrationService = new RegistrationService(msal);

export default registrationService;