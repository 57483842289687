import React, { useEffect, useState } from 'react'
import { Offcanvas, Button, Container, Row, Col } from 'react-bootstrap'
import { InputField } from '../'
import { useTranslation } from 'react-i18next'

const AddCustomAttributeModal = ({show, onModalClose, onModalSave, result, placement='end', backdrop = false, scroll = false}) => {
  const {t} = useTranslation()
  const [ data, setData ] = useState({
    name: null, label: null
  })

  useEffect(()=>{
    setData({name: null, label:null})
  },[show])

  const style = {
    bottom: {
      bottom: "15px",
      position: "absolute"
    },
    offcanvas: {
      top: 60
    }
  }

  const colors = {
    error: 'red',
    success: 'green',
    default: 'black'
  }

  const handleOnCLickSave = async() => {
    onModalSave(data)
  }

  const handleOnInputChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
      ...prevData,
      [id]: value
    }))
  }

  return(
    <Offcanvas style={{...style.offcanvas}} placement={placement} show={show} onHide={onModalClose} backdrop={backdrop} scroll={scroll} >
      <Offcanvas.Header>
        <Offcanvas.Title className={'display-6 fw-bold'}>{t('addCustomAttribute-modal-headline')}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <InputField 
                label={t('input-label-label')}
                controlId='label'
                type='text'
                value={data.label}
                edit={true}
                handleOnChange={handleOnInputChange}
                required={true}
              />
            </Col>
            <Col xs={12} md={12}>
              <InputField 
                label={t('input-label-name')}
                controlId='name'
                type='text'
                value={data.name}
                edit={true}
                handleOnChange={handleOnInputChange}
                required={true}
              />
            </Col>
            <Col xs={12} md={12}>
              {result && <p style={{marginTop:7, color: colors[result.status]}}>{t(result.message)}</p>}
            </Col>
          </Row>
        </Container>
      </Offcanvas.Body>
      <Offcanvas.Body>
        <div style={style.bottom}>
          <Col style={{textAlign:"right"}}>
            <Button style={{margin:"5px"}} variant="secondary" onClick={onModalClose}>{t('button-close')}</Button>
            <Button style={{margin:"5px"}} disabled={!data.label || !data.name} variant="primary" onClick={handleOnCLickSave}>{t('button-save')}</Button>
          </Col>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default AddCustomAttributeModal