import React from 'react'
import { useSelector } from 'react-redux'
import { ListGroup, Fade, Form, Image, Button } from 'react-bootstrap'
import { RegistrationButton } from '../';
import { withTranslation } from 'react-i18next'

import user from './images/Users.svg'
import azureAD from './images/Azure-Active-Directory.svg'
import application from './images/Enterprise-Applications.svg'
import information from './images/Information.svg'

import './registrations.css'

const FormProcess = (props) => {

  const {t} = props
  const tenant_admin = useSelector((state) => state.tenant_admin)
  const styles = {
    row:{
      display: "flex"
    },
    input : {
      padding: "5px"
    },
    btn: {
      textAlign: "right"
    },
    image: {
      height: "50px"
    }
  }

  return(
    <Fade in={true}>
      <Form>
        <ListGroup variant="flush">
          <ListGroup.Item><Image src={azureAD} alt="azure global admin" style={styles.image}/>{t('registration-description-azure')}</ListGroup.Item>
          <ListGroup.Item><Image src={user} alt="azure global admin" style={styles.image}/>{t('registration-description-admin')}</ListGroup.Item>
          <ListGroup.Item><Image src={application} alt="azure global admin" style={styles.image}/>{t('registration-description-application')}</ListGroup.Item>
          {tenant_admin && (<ListGroup.Item><Image src={information} alt="azure global admin" style={styles.image}/>{t('registration-description-vailid')}</ListGroup.Item>)}
        </ListGroup>
        <div style={styles.btn}>
          <Button variant="secondary" onClick={props.handleCancel}>{t('button-cancel')}</Button>{' '}
          <RegistrationButton
            data={props.data}
          />
        </div>
      </Form>
    </Fade>
  )
}

export default (withTranslation()(FormProcess))