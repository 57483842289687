import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IconButton = (props) => {
  return(
    <OverlayTrigger
      key={props.id}
      placement={props.placement}
      overlay={
        <Tooltip id={`tooltip-${props.id}`}>
          <strong>{props.tooltip}</strong>.
        </Tooltip>
      }
    >
      <span style={{...props.style}}>
        <FontAwesomeIcon size={props.size} icon={props.icon} style={{ color: props.color }} fixedWidth onClick={props.onClick} />
      </span>
    </OverlayTrigger>
  )
}

export default IconButton