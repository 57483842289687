export default class Identity {
  constructor(tokenResponse) {
    this.account = tokenResponse.account
    this.rawIdToken = tokenResponse.idToken.rawIdToken
    this.response = tokenResponse
  }

  get idToken() {
    return this.rawIdToken;
  }

  get tenantId() {
    return this.response.tenantId
  }

}