import { NETWORK_START, NETWORK_STOP, NETWORK_ERROR, SET_LOADING, TASK_REMOVE } from "../constants/action-types";
import { TASK_CREATE, TASK_FETCH, TASK_UPDATE } from "../constants/action-types";

import { fetchTasks, createTask, updateTask, deleteTask, startTasks } from '../../utils/requestHelper';

export function editTask(obj){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await updateTask(obj)
      if(result.success === true){
        dispatch({ type: TASK_UPDATE, result: result.result })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}

export function newTask(obj){
  return async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: NETWORK_START });
      const result = await createTask(obj)
      if(result.success === true){
        dispatch({ type: TASK_CREATE, result: result.result })
      }
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
      dispatch({ type: SET_LOADING, payload: true });
    }
  };
}

export function getTasks(limit, skip, search) {
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await fetchTasks(limit, skip, search)
      if(result.success === true){
        dispatch({ type: TASK_FETCH, result: result.result })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}

export function runTasks(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      await startTasks(id);
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}

export function removeTask(_id) {
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await deleteTask(_id)
      if(result.success === true){
        dispatch({ type: TASK_REMOVE, _id})
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}