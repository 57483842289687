import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getAccessToken, getRefreshToken, verifyToken } from './common';
import { fetchAllowedActions } from './requestHelper';

const checkPageAccess = async(path) => {
  return new Promise(async function (resolve, reject) {
    let isUserAuthorised = false;
    let allowedActionsName = [];

    const result = await fetchAllowedActions(path);

    if(result) {
      const permittedActions = result.pages || []
      if( Array.isArray(permittedActions) && permittedActions.length > 0) {
        permittedActions.forEach(element => {   
          if(element.path === path){        
            isUserAuthorised = true
            if(( Array.isArray(element.actions) && element.actions.length > 0)){
              element.actions.forEach(item => {   
                allowedActionsName.push(item.actionUrl);
              });
            }
          }
        });
      };
      resolve({
        isUserAuthorised,
        allowedActionsName
      })
    }
    else
      reject({
        isUserAuthorised,
        allowedActionsName
      })
  })
};

const PrivateRoute = ({ path, component: Component }) => {
  const [ access, setAccess ] = useState();
  const [ accessToken ] = useState(getAccessToken());
  const [ refreshToken ] = useState(getRefreshToken());

  useEffect(()=>{
    setAccess(null)
    checkPageAccess(path).then((checkAccess) => {
      setAccess(checkAccess)
    }).catch(() => {
      setAccess(null)
    });
    verifyToken()
  },[path])

  if(!access && (accessToken && refreshToken))
    return null

  return (accessToken && refreshToken) ? <Component actions={access ? access.allowedActionsName : []} /> : <Navigate to="/" />;
}
 
export default PrivateRoute;