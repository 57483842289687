import React from 'react'
import { useDispatch } from "react-redux";
import { Modal, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { hideNotification } from '../../redux/actions'

const Notification = ({notification}) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  
  if(!notification)
    return null

  const style = {
    m_body: {
      textAlign: 'center'
    },
    m_h1 : {
      fontSize: '5rem',
      fontWeight: 'bold'
    },
    m_p : {
      fontSize: '1.5rem'
    }
  }

  return(
    <Modal
      size="sm"
      show={true}
      onHide={() => dispatch(hideNotification())}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          {t(`notification-modal-${notification.type}`)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{...style.m_body}}>
        <h1 style={{...style.m_h1}}>{t(notification.status)}</h1>
        <p style={{...style.m_p}}>{t(notification.message)}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button style={{margin:"5px"}} variant="primary" onClick={() => dispatch(hideNotification())}>{t('button-close')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Notification