import React, { useState }from 'react';
import AsyncSelect from 'react-select/async';

function createRequestSearch(filter) {
  var result = ""
  if (filter) {
    for (const key in filter) {
      if (filter[key])
        result += `&search=OR(${key}:${filter[key]})`
    }
  }
  return result
}

const InputSelect = (props) => {

  const [ input, setInput ] = useState(null)

  const onChangeFunction = (e) => {
    var obj = {
      id:e.id,
      displayName: e.displayName,
      controlId: props.controlId
    }
    props.handleOnChange(obj);
  }

  const onChangeInput = (newInput) => {
    setInput(newInput);
    return newInput
  }

  const loadOptions = () => {
    const search = createRequestSearch({ displayName: input, description: input })
    return(props.options(search, props.onPremisesSyncDisabled && '&filter=eq(onPremisesSyncEnabled:null)&filter=not(groupTypes:DynamicMembership)'));
  };

  return(
    <div style={{marginTop: "10px"}} key={`async-select-div-${props.controlId}`}>
      <label>{props.label}</label>
      {props.edit ?
      <div>
        <AsyncSelect
          key={`async-select-${props.controlId}`}
          defaultOptions
          loadOptions={loadOptions}
          onChange={onChangeFunction}
          onInputChange={onChangeInput}
          value={props.value && props.value}
          placeholder={props.placeholder && props.placeholder}
          isOptionSelected={option => props.value && (option.displayName === props.value.displayName)}
          getOptionLabel={option => `${option.displayName}`}
          isOptionDisabled={
            props.onPremisesSyncDisabled && (option => ( option.onPremisesSyncEnabled === true ) || (option.mailEnabled === true && option.securityEnabled === false && (typeof option.groupTypes !== 'undefined' && option.groupTypes.length < 1)))
          }
        />
      </div> 
      :
      <p style={{fontWeight: "bold"}} >{props.value && props.value.displayName}</p>
      }
    </div>
  )
}

export default (InputSelect)