import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { signOut } from '../../redux/actions'
import { useNavigate, useLocation } from 'react-router-dom'
import { Container, Row, Col, Card } from 'react-bootstrap'
import FormProcess from './formProcess'
import ImageSection from './imageSection'
import { withTranslation } from 'react-i18next'
import queryString from 'query-string'

import './registrations.css'

const Registration = (props) => {

  const {t} = props
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const [ data, setData ] = useState({
    tenantId: null
  })

  useEffect(()=> {
    if(location.search) {
      const urlSearch = queryString.parse(location.search)
      if(urlSearch.tenatId){
        setData({...data, tenantId: urlSearch.tenatId})
      }
      else {
        navigate('/')
      }
    }
  },[location])

  const handleCancel = () => {
    dispatch(signOut())
  }

  return(
    <Row className={"no-gutter"} >
      <ImageSection />
      <Col md={5} lg={5}>
        <div className={"login d-flex align-items-center py-5"}>
          <Container>
            <Row>
              <Col className={"mx-auto"} md={12} lg={12} >
                <h3 style={{textAlign: 'center'}}>{t('registration-headline')}</h3>
                <h6 style={{textAlign: 'center'}}>{t('registration-content')}</h6>
                <Card.Body>
                  <FormProcess 
                    data={data}
                    handleCancel={handleCancel}
                  />
                </Card.Body>
              </Col>
            </Row>
          </Container>
        </div>
      </Col>
    </Row>
  )
}

export default (withTranslation()(Registration))