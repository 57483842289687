export const NETWORK_ERROR       = "NETWORK_ERROR"
export const NETWORK_START       = "NETWORK_START"
export const NETWORK_STOP        = "NETWORK_STOP"
export const SET_IDENTITY        = "SET_IDENTITY"
export const SET_AVATAR          = "SET_AVATAR"
export const SET_ROLEASSIGNMENTS = "SET_ROLEASSIGNMENTS"
export const SET_ADMIN           = "SET_ADMIN"
export const SET_LOADING         = "SET_LOADING"
export const TOGGLE_SIDEBAR      = "TOGGLE_SIDEBAR"
export const SET_NOTIFICATION    = "SET_NOTIFICATION"
export const SET_TENANT          = "SET_TENANT"
export const SET_ACCESSTOKEN     = "SET_ACCESSTOKEN"
export const SET_REFRESHTOKEN    = "SET_REFRESHTOKEN"
export const SET_ALLOWEDACTIONS  = "SET_ALLOWEDACTIONS"
export const TASK_CREATE         = "TASK_CREATE"
export const TASK_UPDATE         = "TASK_UPDATE"
export const TASK_FETCH          = "TASK_FETCH"
export const TASK_REMOVE         = "TASK_REMOVE"